import React, { useEffect } from 'react';
import Header from '../../components/landingpage/Header';
import Footer from '../../components/landingpage/Footer';
import '../../styles/landingPage/aos.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogAction } from '../../redux/slice/blogs/getAllBlogSlice';

const Blog = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const { getAllBlog } = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBlogAction());
  }, []);


  function renderTextInsidePTag(html) {
    // Replace ul, ol, li tags with line breaks
    let processedHtml = html.replace(/<\/?(ul|ol|li)[^>]*>/g, '\n');
  
    // Replace &nbsp; with regular spaces
    processedHtml = processedHtml.replace(/&nbsp;/g, ' ');
  
    // Remove other HTML tags
    processedHtml = processedHtml.replace(/<[^>]*>/g, '');
  
    // Remove extra spaces and trim the content
    const textContent = processedHtml.replace(/\s+/g, ' ').trim();
  
    return <p>{textContent}</p>;
  }
  
  
    

  return (
    <>
      <Header />
      {/*  BredCrumb-Section  */}
      <div className="bred_crumb" style={{ backgroundImage: 'url("/images/bread_crumb_bg.png")', backgroundRepeat: "no-repeat" }}>
        <div className="container">
          {/* shape animation   */}
          <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
          <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
          <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

          <div className="bred_text">
            <h1>Latest blog post</h1>
            <ul>
              <li><a href="/">Home</a></li>
              <li><span>»</span></li>
              <li><a href="#">Blog</a></li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Blog-Detail-Section-Start --> */}
      <section className="row_am blog_list_main">
        {/* <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          {getAllBlog?.blog?.dataItems?.slice(-1).map((blog) => {
            return (
              <div className="row">
                <div className="col-lg-5" data-aos="fade-in" data-aos-duration="1500">
                  <div className="blog_img">
                    <img src={blog.imageBlog} alt="image" />
                    <span>20 min ago</span>
                  </div>
                </div>

                <div className="offset-lg-1 col-lg-6">
                  <div className="blog_text">
                    <span className="choice_badge">EDITOR CHOICE</span>

                    <div className="section_title">
                      <h2>{blog?.name}</h2>
                      {/* <p className='MainP' dangerouslySetInnerHTML={{ __html: blog.description.slice(0, 500) }} /> */}
                      <p className='MainP'>{renderTextInsidePTag(blog.description.slice(0,800))}</p>
                      <Link to={`/blog/${blog.slug}`}>READ MORE</Link>
                    </div>

                    {/* <div className="section_title">
                  <h2>Top rated app of the year!</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting in
                    dustry lorem Ipsum has been the industrys standard dummy text ev
                    er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                  <Link to={'/blog/blog-1'}>READ MORE</Link>
                </div> */}
                  </div>
                </div>
              </div>
            )
          })}
          {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
      </section>
      {/* <!-- Blog-Detail-Section-end --> */}

      <section className="row_am latest_story blog_list_story" id="blog">
        {/* <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          <div className="row">
            {/* <!-- story --> */}
            {getAllBlog?.blog?.dataItems?.slice(0,-1).map((blog) => {
              return (
                <div className="col-md-4">
                  <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                    <div className="story_img">
                      <img src={blog.imageBlog} alt="image" />
                      <span>45 min ago</span>
                    </div>
                    <div className="story_text">
                      <h3 className='MainH'>{blog.name}</h3>
                      {/* <p className='MainP' dangerouslySetInnerHTML={{ __html: blog.metaDescription.slice(0, 250) }} /> */}
                      <p className='MainP'>{renderTextInsidePTag(blog.description.slice(0,250))}</p>
                      <Link to={`/blog/${blog.slug}`}>READ MORE</Link>
                    </div>
                  </div>
                </div>
              )
            })}


            {/* <!-- story --> */}
            {/* <div className="col-md-4">
              <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                <div className="story_img">
                  <img src="/images/story03.jpg" alt="image" />
                  <span>45 min ago</span>
                </div>
                <div className="story_text">
                  <h3>Top rated app! Yupp.</h3>
                  <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                  <Link to={'/blog/blog-3'}>READ MORE</Link>
                </div>
              </div>
            </div> */}
          </div>
          {/* <!-- row end --> */}

          {/* <!-- Pagination --> */}
          {/* <div className="pagination_block">
            <ul>
              <li><a href="#" className="prev"><i className="icofont-arrow-left"></i> Prev</a></li>
              <li><a href="#">1</a></li>
              <li><a href="#" className="active">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#">4</a></li>
              <li><a href="#">5</a></li>
              <li><a href="#">6</a></li>
              <li><a href="#" className="next">Next <i className="icofont-arrow-right"></i></a></li>
            </ul>
          </div> */}
        </div>
        {/* <!-- container end --> */}
      </section>
      {/* <!-- Story-Section-end --> */}
      <Footer />
    </>
  )
}

export default Blog;