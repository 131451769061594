import React, { useEffect } from "react";
// import "../../styles/landingPage/style.css";
import "../../styles/landingPage/main.css";
import { useDispatch, useSelector } from "react-redux";
import { showAppointmentAction } from "../../redux/slice/appointment/showAppointmentSlice";
import { useParams } from "react-router-dom";
import { handleRelationLanguage } from "../../utils/utils";

const RecordifyLandingPage = () => {
  const dispatch = useDispatch();
  const a = useSelector((s) => s.showAppointment);

  // dynamic id taking from url
  const { id } = useParams();

  useEffect(() => {
    dispatch(showAppointmentAction(id));
  }, []);

  function formatAddress(address) {
    const parts = [];

    if (address?.patientAddress) {
      parts.push(address.patientAddress);
    }

    if (address?.patientlandmark) {
      parts.push(address.patientlandmark);
    }

    if (address?.patientCity && address?.patientpincode) {
      parts.push(`${address.patientCity} - ${address.patientpincode}`);
    }

    if (address?.patientstate) {
      parts.push(address.patientstate);
    }

    if (address?.patientcountry) {
      parts.push(address.patientcountry);
    }

    // If no valid parts are found, return "NA"
    if (parts.length === 0) {
      return "NA";
    }

    // Join the valid parts with a comma and space
    return parts.join(", ");
  }

  const gender = ["Male", "Female", "Other"];
  return (
    <>
      <div>
        <div className="main-bg">
          <div className="col-lg-12">
            <div className="TopBox">
              <div className="TopLogPadding">
                <a className="" href="/">
                  <img src="/images/logo.png" />
                </a>
              </div>
              <div>
                {/* {" "}
                <img src="images/rightCircle.png" /> */}
              </div>
            </div>
          </div>
          <div className="padd">
            <div className="col-lg-8 offset-lg-2">
              <div className="TopBg">
                <h2>Booking Details</h2>
              </div>
              {a.loading ? (
                <h1 style={{ textAlign: "center" }}>Loading...</h1>
              ) : a.appointment?.patientName ? (
                <div className="BoxBg">
                  <div className="box-left">
                    <div className="icon">
                      <img
                        src={
                          a.appointment?.patientImageUrl ||
                          "/images/placeholderimage.svg"
                        }
                        alt="icon"
                        className="icon"
                      />
                    </div>
                    <div className="content">
                      <h2>{a.appointment?.patientName}</h2>
                      <span>{a.appointment?.patientPhoneNo || "NA"}</span>
                    </div>
                  </div>
                  <div className="contabtCol">
                    <h2>Beneficiary Details</h2>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-6">
                            <p>Age:</p>
                            <h3>{a.appointment?.patientAge || "NA"}</h3>
                          </div>
                          <div className="col-6">
                            <p>Gender:</p>
                            <h3>
                              {gender[Number(a.appointment?.patientGender)] ||
                                "NA"}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-6">
                            <p>Relation:</p>
                            <h3>
                              {handleRelationLanguage(
                                a.appointment?.patientRelation
                              ) || "NA"}
                            </h3>
                          </div>
                          <div className="col-6">
                            <p>Mobile:</p>
                            <h3>{a.appointment?.patientPhoneNo || "NA"}</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="row1">
                          <div className="col-12 ">
                            <p>Email:</p>
                            <h3>{a.appointment?.patientEmail || "NA"}</h3>
                          </div>
                          <div className="col-12">
                            <p>Address</p>
                            <h3>
                              {formatAddress(a.appointment)}
                              {/* {a.appointment?.patientAddress +
                                ", " +
                                a.appointment?.patientlandmark +
                                " " +
                                a.appointment?.patientCity +
                                " - " +
                                a.appointment?.patientpincode +
                                ", " +
                                a.appointment?.patientstate +
                                ", " +
                                a.appointment?.patientstate +
                                " " +
                                a.appointment?.patientcountry || "NA"} */}
                              {/* To do */}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contabtCol">
                    <h2>Vaccination Details</h2>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-6">
                            <p>Appointment Date:</p>
                            <h3>
                              {a.appointment?.patientAppointmentDate || "NA"}
                            </h3>
                          </div>
                          <div className="col-6">
                            <p>Vaccine Name:</p>
                            <h3>
                              {a.appointment?.patientVaccinationName?.join(
                                ", "
                              ) || "NA"}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-6">
                            <p>Appointment Number:</p>
                            <h3>
                              {a.appointment?.patientAppointmentBookedNo ||
                                "NA"}
                            </h3>
                          </div>
                          <div className="col-6">
                            <p>Appointment Center:</p>
                            <h3>{a.appointment?.appoimentCenter || "NA"}</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="row">
                          <div className="col-6">
                            <p>Doctor Name:</p>
                            <h3>{a.appointment?.doctorName || "NA"}</h3>
                          </div>
                          <div className="col-6">
                            <p>Payment Status:</p>
                            <h3>Paid</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <h1 style={{ textAlign: "center" }}>Invalid Appointment</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordifyLandingPage;
