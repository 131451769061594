import React, { useState, useEffect } from "react";

const Header = () => {
  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 96);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <header
        className={isScrollValueMoreThanHeaderHeight ? "sticky" : "not-sticky"}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <img src="/images/teekakaran-logo-white.png" alt="image" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                {/* <i className="icofont-navigation-menu ico_menu"></i>  */}
                <div className="toggle-wrap">
                  {/* <span className="toggle-bar"></span> */}
                  <img src="/images/menu.png" />
                </div>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item has_dropdown">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                  {/* <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                                <div className="sub_menu">
                                    <ul>
                                        <li><a href="index.html">Home Defoult</a></li>
                                        <li><a href="../02-app-landing-page-dark-hero/index.html">Home Dark Hero</a></li>
                                        <li><a href="../03-app-landing-page-wave-animation/index.html">Home Wave </a></li>
                                        <li><a href="../04-app-landing-page-gredient-color/index.html">Home Gredient</a></li>
                                        <li><a href="../05-app-landing-page-video-hero/index.html">Home Video</a></li>
                                        <li><a href="../06-app-landing-page-video-hero-full-background/index.html">Home Video 2</a></li>
                                    </ul>
                                </div> */}
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#features">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#how_it_work">
                    How it works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#team">
                    Our Team
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#testimonials">
                    Testimonials
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blog/">
                    Blog
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="modal"
                    data-bs-target="#contact-model"
                  >
                    Contact
                  </a>
                </li>
                {/* <li className="nav-item">
                                <a className="nav-link dark_btn"  data-bs-toggle="modal" data-bs-target="#contact-model">GET STARTED</a>
                            </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {/* <div className="sticky_buttons">
        <ul className="app_btn">
          <li>
            <a href="#">
              <img
                className="blue_img"
                src="/images/appstore_blue.png"
                alt="image"
              />
             
            </a>
          </li>
          <li>
            <a href="#">
              <img
                className="blue_img"
                src="/images/googleplay_blue.png"
                alt="image"
              />
              
            </a>
          </li>
        </ul>
      </div> */}
    </>
  );
};

export default Header;
