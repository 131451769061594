import React from 'react'

const Views = () => {
  return (

    <section className="row_am about_app_section" id="about_app">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-xs">
            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
              <div className="frame_img">
                <img className="moving_position_animatin" src="/images/about-frame.png" alt="image" />
              </div>
              <div className="screen_img">
                <img className="moving_animation" src="/images/family.png" alt="image" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">


            <div className="about_text">
              <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">


                <h2>Awesome facts  <span>about the app.</span></h2>


                <p>
                Teekakaran is one of the innovative healthcare application, who empowers the medical journey of patients and healthcare professionals. We encompass a wide array of functionalities designed to improvise the patient experience and medical outcomes. 
                </p>
                <p>
                Our healthcare management services works for the people of all age group. Some remarkable offerings from Teekakaran to medical fraternity and patients.
                </p>
              </div>


              <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                <li>
                  <div className="icon">
                    <img src="/images/heartbeat.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>Centralized <br/>Tracking</span></p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/images/bell.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>Personalized <br/>Reminders</span></p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/images/artificial-intelligence-icon.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>AI <br/>Enabled</span></p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/images/solution-icon.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>Tailored  <br/>Solutions</span></p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/images/cyber-security.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>Data <br/>Security</span></p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/images/support-data.png" alt="image" />
                  </div>
                  <div className="text">
                    <p><span>Backend<br/>Support</span></p>
                  </div>
                </li>
              </ul>

             
            </div>
          </div>
        </div>

      </div>

    </section>
  )
}

export default Views