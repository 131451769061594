import React from 'react'

const Modern = () => {
  return (
    <section className="row_am modern_ui_section">

      <div className="container">

        <div className="row">
          <div className="col-lg-12">

            <div className="ui_text">
              <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
              <img className="moving_position_animatin" src="/images/abdm_logo.svg" alt="image" />
                <h2>ABDM Compliant</h2>
                <p>
                Teekakaran supports government initiative through Ayushman Bharat Digital Mission (ABDM). We bring all medical facilities under one roof and make it more accessible for healthcare community and patient. 
                </p>
              </div>
              {/* <ul className="design_block">
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Reminders</h4>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Loyalty Points</h4>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Secured Data</h4>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>24/7 support</h4>
                </li>
              </ul> */}
            </div>
          </div>
          {/* <div className="col-lg-6">

            <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
              <div className="left_img">
                <img className="moving_position_animatin" src="/images/abdm_logo.svg" alt="image" />
              </div>

              <div className="right_img">
                <img className="moving_position_animatin" src="/images/secure_data.png" alt="image" />
                <img className="moving_position_animatin" src="/images/modern02.png" alt="image" />
                <img className="moving_position_animatin" src="/images/modern03.png" alt="image" />
              </div>
            </div>
          </div> */}
        </div>

      </div>

    </section>
  )
}

export default Modern