import React, { useEffect } from 'react';
import Header from '../../components/landingpage/Header';
import Footer from '../../components/landingpage/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCmsBySlugAction } from '../../redux/slice/cms/getAllCmsBySlugSlice';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [location]);
    useEffect(() => {
        dispatch(getAllCmsBySlugAction({ slug: 'privacy-policy' }));
    }, []);
    const { getAllCmsBySlug } = useSelector((state) => state);

    return (
        <>
            <Header />
            {/*  BredCrumb-Section  */}
            <div className="bred_crumb" style={{ backgroundImage: 'url("/images/bread_crumb_bg.png")', backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    {/* shape animation   */}
                    <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                    <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                    <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                    <div className="bred_text">
                        <h1>{getAllCmsBySlug?.cms?.title}</h1>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><span>»</span></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- privacy-Detail-Section-Start --> */}
            <section className="row_am privacy_main">
                {/* <!-- container start --> */}
                <div className="container">
                    {/* <p>{getAllCmsBySlug?.cms?.description}</p> */}
                    <div dangerouslySetInnerHTML={{ __html: getAllCmsBySlug?.cms?.description }} />
                    {/* <p>
            This sequestration Policy( “ privacy Policy ”) describes what information Teekakaran Technologies Private Limited( “ Teekakaran ” or the “ Company ” or “ reality ” or “ We ” “ Us ” “ Our a company incorporated under the Companies Act, 2013, having its listed office at Devnerds Pvt. Ltd. BSI Business Park, Ground Floor H-44, Sector 63, Noida, Uttar Pradesh 201301 as the proprietor and driver of the Platform may collect from a stoner( “ You ”, “ Your ” or “ stoner ”), on or through our website https//www.Teekakaran.com/( “ point ”), directly or in relation to services else rendered by Us( inclusively “ Platform ”), similar as facilitation and provision of digital health discussion, and other ancillary telemedicine services through the Platform, that may include installing and furnishing access to tackle or software bias for the perpetration of the Platform, as may be handed by us through the point, and cataloguing a physical discussion with the Registered Medical Practitioner( each a “ Service ”, and inclusively the “ Services ”) and how We use, process, expose and try to cover similar information.
            You agree and understand that the Company is responsible for the operation and conservation of the Platform and all information collected and reused on the Platform is collected and reused by us rigorously about our business.
            </p>
            <p>
            By using Our Platform, or the Services, you confirm that You have read, understood, and agree with the sequestration practices described in this sequestration Policy, the Terms of Use( the “ Terms ”) and the collection, storehouse, and processing of Your information in agreement with them.
            </p>
            <p>
            This sequestration Policy is incorporated by reference into the Terms. Any capitalized terms used but not defined in this sequestration Policy have the meaning given to them in the Terms.
            </p>
            <p>
            The Platform acts as a tool to connect listed medical gurus (“RMP/ Registered Medical Practitioner”) and cases, end- druggies, or guests (“guests”)
            For easy and accessible access to discussion services handed by the Registered Medical Practitioner. The Services would be made available to Registered Medical Practitioners wherein the Registered Medical Practitioner will give discussion services vide digital mediums to the guests.
            </p>
            <p>
            Thus in addition to the collection of data from guests for the provision of Services, the Entity will, for furnishing Services to the Registered Medical Practitioner, be needed to collect and host certain data and information of the Registered Medical Practitioner. Any similar data and information about the Registered Medical interpreters, including but not limited to their name, photos, enrolment ID(accorded to the Medical Practitioner as per the state or public register) qualifications, experience, and specialties, that may be hosted by the Entity, and displayed on the Platform, is for information purposes only and similar information shall not be supposed to quantum to an announcement of the Registered Medical Practitioner, and/ or the services handed by similar Registered Medical Practitioner.
            </p>
            <p>
            We are committed to guarding the particular information of the Registered Medical Practitioner and guests. Further, we take all preventative measures to maintain the confidentiality of the Registered Medical Practitioner’s particular information.
            </p>
            <p>
            In addition to this sequestration Policy governing the collection of client data or information, this sequestration Policy shall apply to the use of the Platform by all Registered Medical interpreters. Consequently, a condition of each Registered Medical Practitioner's use of and access to the Platform and the other services handed by the Entity to Registered Medical Practitioners is their acceptance of this sequestration Policy and the Terms. Any Registered Medical Practitioner is needed to read and understand.
            Here is previous to submitting any sensitive particular data or information to the Entity, failing which they are needed to leave the Platform incontinently and bang cease the operation of the Platform.
            </p>
            
            
            <p>This sequestration Policy is published in compliance with, inter alia</p>
            <ol type="a">
                <li><p>Section 43A of the Information Technology Act, 2000(“IT Act”);</p></li>
                <li><p>Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011(“SPDI Rules”); and</p></li>
                <li><p>Regulation 3( 1) of the Information Technology( interposers Guidelines and Digital Media Ethics Code) Rules, 2021( “ interposers Guidelines ”).</p></li>
            </ol>
            <br/><br/>
            <h4>General Terms</h4>
            <ol type="a">
            <li> <p>By clicking on Continue' during the sign-up process, by penetrating or using the Platform or by using the Service, or by else giving Us Your information, You confirm that You can enter into a fairly binding contract under Indian law, in particular, the Indian Contract Act, 1872, and have read, understood, agreed, and acceded to the practices and programs outlined in this sequestration Policy and agree to be bound by the sequestration Policy.</p></li>
 
            <li><p>You hereby assent to our collection, use, sharing, and exposure of your information as described in this sequestration Policy. We reserve the right to change, modify, add, or cancel portions of the terms</p>
            <p>
            This sequestration Policy, at our sole discretion, at any time, and any continued use of the operation, the Services, or the Platform, following any similar emendations to the sequestration Policy, will be supposed as an implicit acceptance of the sequestration Policy in its amended form. While you are requested to review the sequestration Policy from time to time to keep yourself streamlined with any changes; or variations made to the terms hereof, We shall notify You as per timelines specified under applicable laws about any similar emendations to the sequestration Policy.</p></li>
            
            <li><p>If you are penetrating or using Services on the point from an overseas position, you do so at your threat and shall be solely liable for compliance with any applicable original laws.</p></li>
            
            <li> <p>If you do not agree with any of the terms and conditions of this sequestration Policy, please do not do further to use this point or any Services.</p></li>
            </ol>
            <br/><br/>
            <h4>1.	What information About You is collected on the Platform?  </h4>
            <p>When you pierce the point or use the Service(s), you may give, or we may collect information that may specifically identify you or any other existent. Given below are the types of information that we may collect </p>

            <ol type="a">
                <li>
                <p>Information you give us we admit and store any information you enter on Our Platform or   give us in any other way. When you register on the point, we collect enrolment details similar as phone number, name, geographical address and dispatch address. We corroborate your phone number with the help of a one- time word transferred to your phone number. We may also collect information needed for furnishing you Services on the Platform including, easing discussion bookings with a Registered Medical Practitioner, once medical conventions etc. </p>
                <p>You can choose not to give certain information, but also you may accordingly not be suitable to take advantage of numerous of our features. We may also collect sensitive particular data or information (“SPDI”) about you when you use Our Service(s) on the Platform. This information includes health information We admit from you or, on your behalf, similar as information or records relating to your medical or health history, health status and laboratory testing results, details of treatment plans and drug specified by a Medical guru, lozenge details similar as frequency of lozenge, indispensable drug, individual results, Cases health ids, other health- related information and any other information inferred therefrom. </p>
                <p>We may also collect payment information similar as your payment card number, expiration date, billing address. By using the Service, you assent to the recording, storehouse, and exposure of similar dispatches you shoot or admit for these purposes. We may also store and reuse conventions, treatment notes, recommendations and other data generated by Medical interpreters on or through the Platform, and data participated by third parties similar as diagnostics related information; tradition affiliated information etc. and may retain similar material for our records for the duration of You serving the Services or for any similar period needed or permitted under applicable law.   </p>
                </li>
                <li>
                    <p>
                    In order to have access to the Platform and the features thereof the Registered Medical Practitioner may be needed to produce an account on the Platform, and we may collect the following including without limitation name; stoner- id; dispatch address; word; medical enrolment id; qualification; address; gender; age; phone number; specialties; capsule; educational instrument; medical enrolment documents; visage card; bank details languages any other details.    
                    </p>
                </li>
                <li>
                    <p>
                    Information from Other Sources- We might admit information about you  similar as order details, Your details participated with our mates etc. from other sources including from our mates, advertisers or third parties registered on the Platform and add it to our account information. The Entity, for the purpose of covering the quality of the discussion, may pierce the exchanges between the Registered Medical Practitioner and the guests.
                    </p>
                    <p>
                    The discussion handed by the Registered Medical Practitioner shall be done vide a secured recorded line. The Entity may admit information about the Registered Medical Practitioner’s and/ or client’s browsing history including the URL of the point of the Registered Medical Practitioner and/ or the client’s visited the Platform as well as the Internet Protocol (IP) address, computer operating system and type of web cyber surfer the Registered Medical Practitioner and/ or the client, as the case may be, is using as well as the name of the Registered Medical Practitioner’s and/ or client’s ISP. 
                    </p>
                    <p>
                    The Platform may use temporary eyefuls to store certain data (that is not sensitive particular information or particular information) that is used by the Entity and its third- party service providers for the specialized administration of the Platform, exploration and development etc.
                    </p>
                </li>
                <li>
                    <p>
                    A “cookie” is a small textbook train that may be used, for illustration, to collect information about exertion on the point or the App. Some eyefuls and other technologies may serve to recall information preliminarily indicated or submitted by a stoner. Utmost cyber surfer’s mobile settings allow you to control eyefuls, including whether or not to accept them and how to remove them. You may set utmost cyber surfers mobile operations to notify you if you admit a cookie, or you may choose to block eyefuls with your cyber surfer/ mobile operations. 
                    </p>
                    <p>
                    Tracking technologies may record information similar as internet sphere and host names, internet protocol (IP) addresses, cyber surfer software and operating system types, sluice patterns, and dates and times that our point is penetrated. Our use of eyefuls and other shadowing technologies allows us to ameliorate our point and your experience.
                    </p>
                    <p>
                    At all times, you may refuse all eyefuls on your cyber surfer by changing your settings to the extent admissible on your device. Still, by doing so, you may not be suitable to use certain features on the Platform or take full advantage of all the immolations and interest- grounded advertising. You can remove eyefuls by following directions handed in your mobile’s “help” train or the cyber surfer.    
                    </p>
                </li>
                <li>
                    <p>
                    Third party tools and software we use third party SDKs in our App. maturity of them are different payment options by which you can make a payment for an order. In order to enhance your particular and overall experience some of the tools are used therein.   
                    </p>
                </li>
                <li>
                    <p>Automatic Information We admit and store certain types of information whenever you interact with us. For illustration, we gain certain types of information when your web cyber surfer accesses the point or announcements and other content served by or on behalf ofdocon.in on other web spots. Click then to see exemplifications of the information we admit. </p>
                    <p>
                    We may also admit/ store information about your position and your mobile device, including a unique identifier for your device. We may use this information for internal analysis and to give you with position- grounded services, similar as advertising, hunt results, and other substantiated content.   The IT Act and the SPDI Rules regulate the collection, operation, retention and exposure of particular you agree that you are furnishing all information, including SPDI to us freely. Collection, use and exposure of particular information and SPDI requires your express consent. 
                    </p>
                    <p>
                    Nation with other information available or likely to be you are furnishing us with your concurrence to our use, collection and exposure of the particular information and SPDI. You may choose to not  give us with particular information and SPDI, but in the event that you do so, We will be  unfit to  give you access to our point or give Services through our platform. 
                    </p>
                    <p>
                    Information, which is defined under the SPDI Rules as any information that relates to a natural person, which, either directly or laterally, in combination with other information available or likely to be available to a body commercial, is able of relating similar person. Information, which is defined under the SPDI Rules as any information that relates to a natural person, which, either directly or laterally, in combination with other information available or likely to be available to a body commercial, is able of relating similar person. 
                    </p>
                    <p>
                    You agree that you’re furnishing all information, including SPDI to us freely. Collection, use and exposure of particular information and SPDI requires your express concurrence. You’re furnishing us with your concurrence to our use, collection and exposure of the particular information and SPDI. You may choose to not  give Us with  particular information and SPDI, but in the event that you do so, We'll be  unfit to  give you access to Our point or give Services through our Platform. 
                    </p>
                </li>
            </ol>
            <h4>2. How do we use the information we collect? </h4>
            <ol type="a">
                <li>
                <p>We use information we collect, in a variety of ways in order to give the services on the point and to operate our business, including the following </p> 
                <ul>
                <li>i) To carry out our scores arising from your requests for the products and Services on the Platform; </li>
                <li>ii) To operate and ameliorate the Platform in order to foster a positive stoner experience and to ameliorate our business as a whole;  </li>
                <li>iii) To reuse and deliver your service requests with us;  </li>
                <li>iv) To enable your access to our point to give you Services  </li>
                <li>v) To enable you to initiate discussion with Registered Medical interpreters or easing coming way in the discussion;  </li>
                <li>vi) Analysing data, tracking trends,  erecting algorithms, creating databases for standing systems,  recommendations machines, etc.;  </li>
                <li>vii) Exploration;  </li>
                <li>viii) We use the information that You  give for  similar purposes  similar as responding to your  requests, customising your orders,  perfecting our Platform or communicating with you; </li>
                <li>ix) For non-targeting reasons similar as frequency circumscribing, compliance, billing, announcement reporting or delivery, request exploration or product development purposes; </li>
                <li>x) To misbehave with applicable law;  </li>
                <li>xi) To conduct check-ups and quality assessment procedures;  </li>
                <li>xii) To assay the use of our coffers, troubleshooting problems and perfecting Our Products and Services, by using the information regarding your mobile device and software; </li>
                <li>xiii) reaching druggies, both during and after an order, for updates, resolution of queries, order  details, consultations, follow- up consultations or offering new services; </li>
                <li>xiv) To  probe,  help, or take action regarding illegal conditioning, suspected fraud, situations  involving implicit  pitfalls to the safety of any person, violations of Our Terms, or as  else   needed by law;  </li>
                <li>xv) To respond to any queries that You may have, and to communicate information to You,  including  announcements of any  elevations or  cautions, any changes updates to the point, or the   preface of any future  freights or charges that We may collect at the time for  copping products  or provision of our Services to You; or  </li>
                <li>xvi) To  communicate  You, by way of SMS, dispatch and phone calls, from time to time to record Your   precious feedback on Our products and Services, as they  presently stand, and/ or any implicit  products and services that may be offered in the future;   </li>
                </ul> 
                </li>
                <li>
                    <p>We may use “eyefuls” information and “automatically collected” information we collect on the Platform to  </p>
                    <ul>
                        <li>i)  Epitomize Our Services, similar as flashing back your information so that you will not have to re-up it during your visit or the coming time you mileage the Service </li>
                        <li>ii)  Give customized announcements, content, and information; </li>
                        <li>iii) Examiner and dissect the effectiveness of the Service and third- party marketing conditioning; </li> 
                        <li>iv) Examiner total point operation criteria  similar as total number of callers and runners viewed; and </li> 
                        <li>v) Track your entries, cessions, and status in any elevations or other conditioning on the Service </li>
                    </ul>
                    <p></p>
                </li>
                <li>
                    <p>
                    We may pierce or store your information if it is necessary to descry, help or address fraud and other illegal exertion or to cover the safety, property or rights of the Platform or others.  
                    </p>
                </li>
                <li>
                    <p>
                    We may use information regarding your position or the position of your device through which you pierce the service for a number of purposes, including without limitation to confirm you are located in a governance in which the service is offered and to identify an applicable Registered Medical Practitioner.
                    </p>
                </li>
                <li>
                    <p>
                    We may collect,  dissect, use, publish,  produce and  vended-identified information, of which your  particular or sensitive  particular information might be an element, for any business or other purpose not banned by applicable law, including for  exploration and marketing purposes.(  inclusively “ Purposes ”).  
                    </p>
                </li>
            </ol>
            <h4>3. Do we partake the Information we admit?  </h4>
            <ol type='a'>
                <li>
                    <p>Information about you and the other druggies of the Platform forms an integral part of our business. We only partake the  particular information as described below after  icing that similar third party has enforced measures to assure data protection measures and that are either subject to this sequestration Policy or follow practices at least as defensive as those described in this sequestration Policy. </p>
                    <p>
                    By using the point or the App, you accept the terms hereof and hereby concurrence to the storehouse and processing of the particular information and SPDI by third parties. We, have arrangements with third parties (including without limitations as mentioned below) similar as cells or group companies, service providers; Registered Medical interpreters and payment gateways who are the intended donors and may have access to the particular information and SPDI but shall not expose similar information including SPDI further to any other existent/ reality. Some of the third parties include   
                    </p>
                    <p>I.	transferring postal correspondence, newsletters and-mail, removing  repetitious information from  client lists, analysing data,  furnishing marketing  backing,  discussion with Registered Medical interpreters,  furnishing hunt results and links( including paid  rosters and links), processing card payments and  furnishing  client service. They have access to particular information demanded to perform their functions but may not use it for other purposes. </p>

                    <p>Further, they must reuse the particular information in agreement with this sequestration Policy and as permitted by applicable law. It is clarified that we will not be responsible and liable for the acts of deletions and commissions of similar third parties associated with us. Still, we may grease resolving any issue you may face with similar third- parties.</p>
   
                    <p>II.	Our Legal scores We may release account and other particular information when we believe in good faith that similar release is applicable to misbehave with applicable law including </p>
                    <p>(1) conform to legal conditions or misbehave with legal process;(2) cover rights or property or combined companies; (3) help a crime or in interest of public security; or (4) cover particular safety of Our druggies or the public. We may also expose your particular information to apply or apply Our Terms and other agreements; or cover the rights, property or our safety, safety of our druggies or others. This includes swapping information with other companies, organisations, government or nonsupervisory authorities for fraud protection and credit threat reduction;</p>
                    <p>iii) Participating upon  junction or admixture group transfer any third party to which we transfer  or  vend our  means,  combine or consolidate with, will have the right to continue to use the information( including SPDI)  handed to Us by you, in agreement with the Terms and this Privacy Policy. <br/>
                    We may  expose information to our  mates, cells, accessories, group  realities, investors, stakeholders or implicit associates in an anonymized and aggregate manner, so that they too may understand how druggies use our point and enable us to produce a better overall experience for You; and  </p> 
                    <p>iv) Perfecting our business you admit that We have a right to use a recorded  dupe of Your telephonic  discussion and-prescription with Your Registered Medical Practitioner, for  perfecting Our Services and abetting you in  earning targeted  discussion for any  beginning medical condition. We may transfer  similar  particular Information and SPDI to a third party, including persons outside India, to ameliorate product and service immolations while taking commercially reasonable  way to try and  insure, that the philanthropist adheres to the applicable laws for pricing data protection as is  stuck by Us   </p>

                </li>
                <li>
                    <p>
                    Transfer to third parties and outside India Subject to applicable law, We may at our sole discretion, transfer particular information and SPDI to any other body commercial (as defined under the Information Technology Act, 2000) that ensures at least the same position of data protection as is handed by us under the terms hereof, located in India or any other country. The Registered Medical Practitioner shall insure confidentiality of the Registered Medical Practitioner’s account details including without limitation username and word, and the Registered Medical Practitioner shall incontinently notify us without any detention of any factual or unauthorized use the account details.</p>
                    <p>
                    We shall, in no event, be responsible for the breach of security or of any acts deletions of the third parties including without limitation acts of Government, breach of security, encryption, computer hacking, unauthorized access to computer data and storehouse device. By using the point and the App, you accept the terms hereof and hereby concurrence to Us, participating with and/ or processing of your particular information and SPDI by third parties, including in any position outside India, handed that they insure that your SPDI is defended in compliance with norms that are similar to the norms of protection swung to it in India or original transnational norms.  
                    </p>
                </li>
            </ol>
            <h4>4. How Secure Is Information About Me? </h4>
            <ol type='a'>
                <li>
                We maintain electronic, physical and procedural safeguards in connection with the collection, storehouse and exposure of particular information (including SPDI). Our security procedures may warrant that we may sometimes request evidence of identity before we expose particular information to you.   
                </li>
                <li>
                We work to  cover the security of your information during transmission by using Secure Sockets Layer( SSL) software, which encrypts information you input in addition to maintaining security of Your information as per the transnational  norms on" Information Technology Security ways Information Security Management System- Conditions".      
                </li>
                <li>
                We  circumscribe access to particular information, to our workers and agents who need to know that information in order to reuse it for Us, and who are subject to strict contractual confidentiality  scores, and may be  chastened or whose relationship with Us may terminate if they fail to meet these  scores.     
                </li>
                <li>
                No hand or director will have knowledge of your word of your account on the point or the operation. It is important for you to cover your account against unauthorized access to your word and your mobile phone, as detailed in the ‘stoner Account, word and Security’ section of the Terms. You must be sure to log off from the point or when you have finished use thereof. We do not take over any liability for any unauthorised use of your account and word.       
                </li>
                <li>
                If you suspect any unauthorized use of your account, you must incontinently notify us by transferring a dispatch to the contact details indicated in the contact section. You shall be liable to compensate us due to any loss suffered by us due to similar unauthorized use of your account or word.      
                </li>
                <li>
                Under certain circumstances, we shall not be suitable to take your previous  concurrence before  telling Your information in case the information is demanded by government agencies or else  commanded under law to gain SPDI, or during disquisition of cyber incidents, execution of offenses etc.      
                </li>
                <li>
                We shall not be responsible for any breach of security or for any conduct of any third parties or events that are beyond our reasonable control including but not limited to acts of government, computer hacking, unauthorised access to computer data and storehouse device, computer crashes, breach of security and encryption, poor quality of internet service or telephone service of the stoner, etc.
                </li>
            </ol>
            <br/><br/>

            <h4>5. What Information Can I Access?  </h4>
            <p>The Platform gives you access to a broad range of information about your account and your relations with the Platform for the limited purpose of viewing and, in certain cases, modifying, and deleting information handed on the point. You have the option to  conclude- out of  voluntary services  similar as  entering promotional accoutrements  etc. and should You ask  to  conclude- in to  similar services at the time of  subscribing up you can choose to do so. You may amend any inaccurate or deficient particular information or SPDI, as feasible. </p>
            <p>However, the Company reserves the right to reuse  similar erasure or  omission without notifying the RMP or any third- party, upon  entering your request for exercising your right to erasure with respect to that information from the Platform database, If you are desirous of deleting or erasing your Information including SDPI from the Platform database. Once your request is reused, similar omission shall be final, and the Company disclaims all responsibility for furnishing you any similar deleted information in the future. Further, the Company shall not be liable for the RMP’s failure to maintain a separate record of similar information as commanded by applicable laws. </p> 
            <br/><br/>

            <h4>6. Third- party links </h4>
            <p>The point may include hyperlinks to colourful external websites, and may also include announcements, and hyperlinks to operations, content or coffers (“Third Party Links”). We have no control over similar Third Party Links present on the point or the operation, which are handed by persons or companies other than us. You admit and agree that we are not responsible for any collection or exposure of your information by any external spots, operations, companies or persons thereof. </p>
            <p>The presence of any Third Party Links on our point or App, cannot be demonstrated as a recommendation, countersign or supplication for the same, or any other material on or available via similar Third Party Links. You further admit and agree that we are not liable for any loss or damage which may be incurred by you as a result of the collection and/ or exposure of your information via Third Party Links, as a result of any reliance placed by you on the absoluteness, delicacy or actuality of any advertising, products services, or other accoutrements on, or available via similar Third Party Links. </p>
            <p>
            This will include all deals, and information transmitted therein, between you and any similar third party spots or operations or coffers, similar deals are rigorously-partite. We shall not be liable for any controversies arising from or in connection with similar deals between you and the forenamed third parties.   
            Similar third party websites, and external operations or coffers, accessible via the Third Party Links may have their own privacy programs governing the collection, storehouse, retention and exposure of your information that you may be subject to. We recommend that you exercise reasonable industriousness, as you would in traditional offline channels and practice judgment and common sense before committing to any sale or exchange of information, including but not limited to reviewing the third party website or operation’s sequestration policy.  </p>
            <br/><br/>

            <h4>7. Retention of Information  </h4>
            <ol type='a'>
                <li>
                <p>Any  particular information or SPDI collected in  agreement with this sequestration Policy will be collected by Us and retained or stored either by Us or by Amazon Web Services, located at Amazon Internet Services Private Ltd, Block E, 14th Floor, Unit Nos. 1401 to 1421 International Trade Tower, Nehru Place, New Delhi, Delhi, 110019 We also have measures in place similar that your SPDI which is in our possession or under our control, is destroyed and/ or anonymized as soon as it is reasonable to assume that <br/>
                (i) The purposes for which your SPDI has been collected have been fulfilled; and (ii) retention is no longer necessary for any other reason, or under applicable law. </p>  
                </li>
                <li>
                <p>We may, still, reserve the right to retain and store your particular information for our business purposes, whether similar particular information has been deleted or not. After a period of time, your data may be anonymized and added up and also may be held by us as long as necessary, to enable purchases of products and provision of services or for analytics purposes  </p>
                </li>
                <li>
                <p>If you wish to withdraw your concurrence for recycling your particular information and SPDI, cancel your account, or request that we no longer use your particular information and SPDI to deliver our products or give you Services, please communicate us at details indicated in the contact section. Please note still that your pull out of concurrence or cancellation of account may affect in us not being suitable to deliver you products or give you with our Services or terminate any being relationship that we may have with you. </p>  
                </li>
                <li>
                <p>Please note that uninstalling the operation will not affect in omission of your particular information or SPDI. </p>
                </li>
            </ol>
            <br/><br/>

            <h4>8. Changes to Your Information  </h4>
            <p>
            You may review, correct, update, and change the information that you have handed by logging into your account. Still, you are not permitted to cancel any part of the particular information or any other information generated on the Platform. You may modernize your information at any point by writing to us at the details indicated below in the contact section. Should You choose to modernize your particular information or SPDI or modify it in a way that is not empirical by Us, or leads to similar information being incorrect, we will be unfit to give you with access to our point or the Services, as described under the Terms, and similar revision may be regarded as the stoner seeking to discontinue his or her access to Our point or the Services.   
            </p>
            <p>
            We reserve the right to corroborate and authenticate your identity and your particular information in order to insure accurate delivery of products and services. Access to or correction, streamlining or omission of your particular information or SPDI may be denied or limited by us if it would violate another person’s rights and/ or isn’t else permitted by applicable law.  
            </p>
            <br/><br/>

            <h4>9. Notices </h4>
            <p>
            Still, please communicate us with a thorough description and We'll try to resolve the issue for You, If You have any concern about privacy or grievances on the point or the App. However, You may address them to our office. If you have any enterprises or questions in relation to this sequestration Policy. Devnerds Pvt. Ltd. BSI Business Park, Ground Floor H-44, Sector 63, Noida, Uttar Pradesh 201301, India info@Teekakaran.com.   We shall endeavour to resolve Your grievances within 15( fifteen) days from the date of damage of  similar grievance.  
            </p>
            <br/><br/>

            <h4>10. Eclectic </h4>
            <ol type="a">
                <li>
                <p>Disclaimer we cannot insure that all of your particular information and be bared in ways not else described in this sequestration Policy. thus, although We're committed to  guarding Your  sequestration, we don't promise, and you shouldn't anticipate, that Your information or private dispatches will always remain private. As a stoner of the point or the App, you understand and agree that you assume all responsibility and threat for your use of the point or the App, the internet generally, and the information you post or access and for your conduct on and off the point or the App. The Company, in no event, shall be responsible and/ or liable for the acts deletions of the Registered Medical Practitioner. The Registered Medical Practitioner shall always be responsible for compliance with applicable law. </p>  
                </li>
                <li><p>Indemnity You agree and  take over to compensate Us in any suit or  disagreement by any third party arising out of  exposure of information by you to third parties either through our point or  else and your use and access of websites,  operations and  coffers of third parties. We assume no liability for any conduct of third parties with regard to your particular information or SPDI which you may have bared to similar third parties.</p></li>
                <li>
                <p>Severability Each clause of this sequestration Policy shall be and remain separate from and independent of and severable from all and any other clauses herein except where else expressly indicated or indicated by the environment of the sequestration Policy. The decision or protestation that one or further clauses are null and void shall have no effect on remaining clauses of this sequestration Policy.</p>
                </li>
            </ol> */}

                </div>
                {/* <!-- container end --> */}
            </section>
            {/* <!-- privacy-Detail-Section-end --> */}
            <Footer />
        </>
    )
}

export default PrivacyPolicy;