import React from "react";

const Faq = () => {
  return (
    <section className="row_am faq_section" id="faq_section">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <h2>
            <span>FAQ</span>
          </h2>

          {/* <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe
            standard dummy.</p> */}
        </div>

        <div className="faq_panel">
          <div className="accordion" id="accordionExample">
            <div className="card" data-aos="fade-up" data-aos-duration="1500">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <i className="icon_faq icofont-plus"></i>Can we maintain medical records electronically?
                  </button>
                </h2>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">
                  <p>
                  Yes, you can keep your reports, prescription, and other medical history electronically.
                  </p>
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="1500">
              <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i className="icon_faq icofont-plus"></i> Is it possible to get personalized reminders for vaccines?
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">
                  <p>
                  Yes, we will send reminders.
                  </p>
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="1500">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <i className="icon_faq icofont-plus"></i>Can we get medicines at our doorstep?
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">
                  <p>
                  Yes you can order medicines from the comfort of your home.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="1500">
              <div className="card-header" id="headingFour">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <i className="icon_faq icofont-plus"></i>How to get healthcare updates from government?
                  </button>
                </h2>
              </div>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFourree"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">
                  <p>
                  Our app keep you posted about latest health schemes and guidelines.
                  </p>
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="1500">
              <div className="card-header" id="headingFive">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <i className="icon_faq icofont-plus"></i>Can we locate the nearby vaccination center?
                  </button>
                </h2>
              </div>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFiveree"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">
                  <p>
                  Yes, you can get the list of nearby vaccination centers on Teekakaran.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
