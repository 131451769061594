import React, { useEffect } from 'react';
import Header from '../../components/landingpage/Header';
import Footer from '../../components/landingpage/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCmsBySlugAction } from '../../redux/slice/cms/getAllCmsBySlugSlice';
import { useLocation, useParams } from 'react-router-dom';

const Pricing = () => {
    return (
        <>
            <Header />
            <div className="pricing">
                {/*  BredCrumb-Section  */}
                <div className="bred_crumb" style={{ backgroundImage: 'url("/images/bread_crumb_bg.png")', backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        {/* shape animation   */}
                        <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                        <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                        <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Pricing</h1>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><span>»</span></li>
                                <li><a href="#">Pricing</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section class="row_am pricing_section" id="pricing">
      <div class="container">
        <div class="section_title">
          <h2>Easy On  <span> Pocket</span></h2>
          
        </div>

        <div class="pricing_pannel monthly_plan active">
          <div class="row">
            <div class="col-md-4">
              <div class="pricing_block">
                <div class="icon">
                  <img src="images/standard.png" alt="image" />
                </div>
                <div class="pkg_name">
                  <h3>Standard</h3>
                  <span>Subscription</span>
                </div>
                <span class="price">₹49</span>
                <span>validity</span>
                <ul class="benifits">
                  <li>
                    <p>  <span>3 Months</span></p>
                  </li>
                </ul>
                <a href="#" class="btn white_btn">BUY NOW</a>
              </div>
            </div>

            <div class="col-md-4">
              <div class="pricing_block highlited_block">
                <div class="icon">
                  <img src="images/unlimited.png" alt="image" />
                </div>
                <div class="pkg_name">
                  <h3>Premium</h3>
                  <span>Subscription</span>
                </div>
                <span class="price">₹99</span>
                <span>validity</span>
                <ul class="benifits">
                  <li>
                    <p>  <span>9 Months</span> </p>
                  </li>
                </ul>
                <a href="#" class="btn white_btn">BUY NOW</a>
              </div>
            </div>

            <div class="col-md-4">
              <div class="pricing_block">
                <div class="icon">
                  <img src="images/premium.png" alt="image" />
                </div>
                <div class="pkg_name">
                  <h3>Lifetime</h3>
                  <span>Subscription</span>
                </div>
                <span class="price">₹299</span>
                <span>validity</span>
                <ul class="benifits">
                  <li>
                    <p> <span>Lifetime</span>  </p>
                  </li>
                </ul>
                <a href="#" class="btn white_btn">BUY NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
            </div>
            
            <Footer />
        </>
    )
}

export default Pricing;