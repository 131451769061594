import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper";

const Trusted = () => {
  return (
    <section className="row_am trusted_section">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">

          <h2>Advisory Board</h2>

          <p>Elevating healthcare standards through unwavering trust and support and becoming your reliable partner in healthcare.</p>
        </div>
        <div className="company_logos">
          <div id="company_slider">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            effect="slide"
            speed={1200}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              // Breakpoint for small devices (e.g., mobile phones)
              576: {
                slidesPerView: 1,
              },
              // Breakpoint for medium devices (e.g., tablets)
              768: {
                slidesPerView: 1,
              },
              // Breakpoint for large devices (e.g., laptops and desktops)
              992: {
                slidesPerView: 2,
              },
              // Breakpoint for extra-large devices (e.g., large desktops)
              1200: {
                slidesPerView: 2,
              },
            }}
            modules={[FreeMode, Pagination, Autoplay]}
            className="mySwiper"
          >
                <SwiperSlide>
                  <div className="item">
                    <div className="experts_box">
                    <img src="images/team-2.png" alt="image" />
                    <div className="text">
                      <h3>Dr.Prakhar Garg </h3>
                      <span>Sr.Advisor</span>
                    </div>
                  </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="item">
                    <div className="experts_box">
                    <img src="images/team-3.png" alt="image" />
                    <div className="text">
                      <h3>Dr.Kapil Agrawal</h3>
                      <span>Advisor</span>
                    </div>
                  </div>
                  </div>
                </SwiperSlide>

               

                {/* <SwiperSlide>
                  <div className="item">
                    <div className="logo">
                      <img src="/images/slack.png" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="item">
                    <div className="logo">
                      <img src="/images/envato.png" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="item">
                    <div className="logo">
                      <img src="/images/paypal.png" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="item">
                    <div className="logo">
                      <img src="/images/spoty.png" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="item">
                    <div className="logo">
                      <img src="/images/shopboat.png" alt="image" />
                    </div>
                  </div>
                </SwiperSlide> */}
          </Swiper>
          </div>
        </div>

      </div>

    </section>
  )
}

export default Trusted