import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify } from "../../../utils/utils";

// d758ad90b24bf7f2976cff0abd2d1f9ab8a69e222f065f6547042f40d2b43592fca7646b4fec620384488a68128ab5e2486ccb20a8cef34eb277375e43251eac7aaaf29bd952430c5898314d7c7e85ed0fd670ff3a255a80073c3dfa95f15834c64b413cbf

export const showAppointmentAction = createAsyncThunk(
  "showAppointment",
  async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}vac/api/viewOrder/${id}`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (!response.ok) {
        // Handle error here if the response is not OK
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  appointment: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const showAppointmentSlice = createSlice({
  name: "showAppointment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showAppointmentAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(showAppointmentAction.fulfilled, (state, { payload }) => {
        state.appointment = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
        notify(payload.message);
      })
      .addCase(showAppointmentAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default showAppointmentSlice.reducer;
