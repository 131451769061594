import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiRequest, notify } from "../../../utils/utils";

export const getAllCmsBySlugAction = createAsyncThunk(
  "getAllCmsBySlug",
  async ({ slug }) => {
    return await makeApiRequest(`api/cms/getSlugView?slug=${slug}`, {
      //   token: getToken(),
      apiKey: process.env.REACT_APP_API_KEY,
    });
  }
);

const initialState = {
  cms: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getAllCmsBySlugSlice = createSlice({
  name: "getAllCmsBySlug",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCmsBySlugAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getAllCmsBySlugAction.fulfilled, (state, { payload }) => {
        state.cms = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
        notify(payload.message);
      })
      .addCase(getAllCmsBySlugAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getAllCmsBySlugSlice.reducer;
