import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper";

const Hero = () => {
  return (
    <section className="banner_section">
      <div className="container">
        <div className="anim_line">
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
          <span>
            <img src="/images/anim_line.png" alt="anim_line" />
          </span>
        </div>

        <div className="row">
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="banner_text">
              {/* <h1>
                Protecting You,<span>Your Loved Ones, and Beyond.</span>
              </h1> */}
              <img
                    className="tagline"
                    src="/images/tagline.png"
                    alt="image"
                    width="300px"
                  />

              <p>
              <br/><br/>
              It has become a cornerstone in the digital transformation for healthcare. Our ecosystem is multilingual and user interface ensures easy accessibility for medical fraternity and patients. <br/><br/>
With Digital Bharat mission, Teekakaran has infused ground-breaking elements that setting benchmark for excellence in patient care.

              </p>
            </div>

            {/* <ul className="app_btn hidden-xs">
              <li>
                <a href="#">
                  <img
                    className="blue_img"
                    src="/images/appstore_blue.png"
                    alt="image"
                  />
                  <img
                    className="white_img"
                    src="/images/appstore_white.png"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dev.teekakaran"
                  target="_blanck"
                >
                  <img
                    className="blue_img"
                    src="/images/googleplay_blue.png"
                    alt="image"
                  />
                  <img
                    className="white_img"
                    src="/images/googleplay_white.png"
                    alt="image"
                  />
                </a>
              </li>
            </ul> */}

            <div className="used_app">
              {/* <ul>
                <li><img src="/images/used01.png" alt="image" /></li>
                <li><img src="/images/used02.png" alt="image" /></li>
                <li><img src="/images/used03.png" alt="image" /></li>
                <li><img src="/images/used04.png" alt="image" /></li>
              </ul> */}
              {/* <p>Endorsed by 50+ doctors</p> */}
            </div>
          </div>

          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="banner_slider">
              <div className="left_icon">
                <img src="/images/message_icon.png" alt="image" />
              </div>
              <div className="right_icon">
                <img src="/images/shield_icon.png" alt="image" />
              </div>
              <div id="frmae_slider" className="owl-carousel owl-theme">
                <Swiper
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  effect="slide"
                  speed={1200}
                  slidesPerView={1}
                  spaceBetween={30}
                  freeMode={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    // Breakpoint for small devices (e.g., mobile phones)
                    576: {
                      slidesPerView: 1,
                    },
                    // Breakpoint for medium devices (e.g., tablets)
                    768: {
                      slidesPerView: 1,
                    },
                    // Breakpoint for large devices (e.g., laptops and desktops)
                    992: {
                      slidesPerView: 1,
                    },
                    // Breakpoint for extra-large devices (e.g., large desktops)
                    1200: {
                      slidesPerView: 1,
                    },
                  }}
                  modules={[FreeMode, Pagination, Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="item">
                      <div className="slider_img">
                        <img src="/images/screen.png" alt="image" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item">
                      <div className="slider_img">
                        <img src="/images/screen-1.png" alt="image" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item">
                      <div className="slider_img">
                        <img src="/images/screen-2.png" alt="image" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item">
                      <div className="slider_img">
                        <img src="/images/screen-3.png" alt="image" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="slider_frame">
                <img src="/images/mobile_frame_svg.svg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
