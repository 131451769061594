import React from 'react'

const NewsLetter = () => {
  return (
    <section className="newsletter_section">

      <div className="container">
        <div className="newsletter_box">
          <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">

            <h2>Subscribe newsletter</h2>

            <p>Never stop exploring the updates</p>
          </div>
          <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Enter your email" />
            </div>
            <div className="form-group">
              <button className="btn">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>

    </section>
  )
}

export default NewsLetter