import React, { useEffect } from 'react';
import Header from '../../components/landingpage/Header';
import Footer from '../../components/landingpage/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCmsBySlugAction } from '../../redux/slice/cms/getAllCmsBySlugSlice';
import { useLocation, useParams } from 'react-router-dom';

const CancellationRefund = () => {
    return (
        <>
            <Header />
            <div className="pricing">
                {/*  BredCrumb-Section  */}
                <div className="bred_crumb" style={{ backgroundImage: 'url("/images/bread_crumb_bg.png")', backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        {/* shape animation   */}
                        <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                        <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                        <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Cancellation and Refund Policy</h1>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><span>»</span></li>
                                <li><a href="#">Cancellation and Refund Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section class="row_am refund_section" id="refund">
      <div class="container">
      <p>The services provided by TeekaKaran are approved doctors (RMP / Practitioner) who use the TeekaKaran system for conducting teleconsultations; support the security of patient records in electronic systems, remind patients of medications, vaccines and advice; help the operator create an electronic record of prescriptions that patients can access whenever needed, thus making the process easier to access. Fees of TeekaKaran are paid to the operator, so no cancellation event will take place. However, in case of any query regarding cancellation, you can contact at info@teekakaran.com.</p>
      <p>
    The refund and cancellation process for a vaccination application can vary depending on the situations. Typically, when someone wishes to cancel their vaccination appointment and request a refund, the first step is to reach out to the healthcare facility or organization through the contact details or customer service channels provided during the booking process. It is essential to initiate this communication well in advance of the scheduled appointment date to allow for proper processing and to increase the likelihood of obtaining a refund.</p>
    <p>
    In many cases, the refund process may involve completing a formal refund request form provided by the healthcare provider. This form typically requires details such as the appointment date, booking reference number, and a brief explanation for the cancellation. Once the request is submitted, the healthcare facility's administrative team will review it and process the refund according to their established policies.
    Timely and clear communication with the support team of TeekaKaran is essential for a smooth refund and cancellation process. It's advisable to keep records of all correspondence and any confirmation emails related to the appointment and refund request. Additionally, patience may be necessary, as processing times for refunds can vary and may depend on the provider's administrative workload.</p>
    <p>
    The payment required to be made by the Contractor for the check amount for the use of TeekaKaran for the performance of the above mentioned services shall be made within ten (10) days from the date of receipt of the invoice. If the user has any questions about the Platform or has any questions about cancellations and returns, he can contact us within seven (7) days of TeekaKaran issuing the invoice. If there is no agreement, the disputed amount will be refunded within seven (7) to fifteen (15) working days depending on the payment method.</p>

      </div>
    </section>
            </div>
            
            <Footer />
        </>
    )
}

export default CancellationRefund;