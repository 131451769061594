import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/landingPage/Home";
import Blog from "./components/blog/Blog";
import PrivacyPolicy from "./components/home/privacypolicy";
import TermCondition from "./components/home/TermCondition";
import CancellationRefund from "./components/home/CancellationRefund"
import Pricing from "./components/home/pricing";
import SingleBlog from "./components/blog/SingleBlog";
import "./styles/landingPage/style.css";
import "./styles/landingPage/responsive.css";
import RecordifyLandingPage from "./pages/landingPage/RecordifyLandingPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route
          path="/book-appointment/:id"
          exact
          element={<RecordifyLandingPage />}
        />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog/:slug" exact element={<SingleBlog />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/term-condition" element={<TermCondition />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/cancellation-refund" exact element={<CancellationRefund />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
