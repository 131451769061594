import React, { useEffect } from "react";
import Header from "../../components/landingpage/Header";
import Footer from "../../components/landingpage/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllCmsBySlugAction } from "../../redux/slice/cms/getAllCmsBySlugSlice";
import { useLocation } from "react-router-dom";

const TermCondition = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    dispatch(getAllCmsBySlugAction({ slug: "term-condition" }));
  }, []);
  const { getAllCmsBySlug } = useSelector((state) => state);

  return (
    <>
      <Header />
      <div className="privacy-policy">
        {/*  BredCrumb-Section  */}
        <div
          className="bred_crumb"
          style={{
            backgroundImage: 'url("/images/bread_crumb_bg.png")',
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            {/* shape animation   */}
            <span className="banner_shape1">
              {" "}
              <img src="/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="/images/banner-shape3.png" alt="image" />{" "}
            </span>

            <div className="bred_text">
              <h1>{getAllCmsBySlug?.cms?.title}</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="#">Term & Condition</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <section className="row_am privacy_main">
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: getAllCmsBySlug?.cms?.description,
              }}
            />
            {/* <h4>Teekakaran Terms and Conditions for Users</h4>
                <p>We ask that you carefully read them, because your use of the service is contingent upon your acceptance and compliance with the following terms and conditions ("Terms"). </p>
                <br/><br/>

                <h4>General</h4>
                <p>Teekakaran is operated by Devnerds Private Limited (hereinafter "Company" or "We" or "Us" or "Us" or "Us" or "Us" or "Us") under the provisions of the Companies Act, 2013 A company incorporated in , with its registered address at Volans Infomatics Pvt. Ltd. BSI Business Park, Ground Floor H-44, Sector 63, Noida, Uttar Pradesh 201301, India. Use of the Site is offered to you conditioned on your acceptance of all terms contained in these Terms, including the applicable policies, which are incorporated herein by reference, as well as any changes made and posted by the Company in its sole discretion. on the Site, including charging additional fees for access or use of the service(s).</p>
                <p>
                In these Terms of Use, where the context so requires, "you" or "user" or "your" means any natural or legal person who has agreed to become a user of the Website in accordance with the conditions below. The Company is not required to notify you of any changes to the Terms of Use. The amended terms are available on the website. Use of the Website and Services will be governed by the most recent version of the Terms available on the Website at the time of use. You are requested to visit the website regularly for the latest terms. It is your responsibility to check the terms regularly for changes. The Company may require you to agree to any updated terms before using the Site and the services provided on it. Unless such separate consent is required, your continued use of the Site following changes to the Terms will constitute your acceptance of those changes.
                </p>
                <br/><br/>

                <h4>Terms of use</h4>
                <p>1. By accessing or using the Site or ordering or using our Services, you agree that you have read, understood and are bound by the Terms. These Terms constitute a legal and binding agreement between you and the Company, on the one hand, subject to the provisions of the Information Technology (Guidelines for Intermediaries) Regulations 2011, which are made under the Information Technology Act 2000 and the Telemedicine Practice Guidelines. ("TP"). Instructions"). </p>
                <p>2. By using the website, you agree to give your consent to the use of telemedicine services in accordance with the terms and conditions below or other terms and conditions that may apply to you from time to time.  </p>
                <p>3. This electronic record is computer generated and does not require physical or digital signatures.  </p>
                <p>4. If you do not wish to be bound by the Terms, do not use the Site, browse or otherwise interact on the Site or use the Services. </p>
                <p>5. You also consent to the doctor and the company referring you to health care providers after the consultation.</p>
                <br/><br/>

                <h4>Privacy Policy </h4>
                <p>
                Please see our privacy page  <br/>
                By using the website or subscribing to our services, you agree that you have read and fully understood the stated privacy policy. You further agree that the terms and content of such privacy policy are acceptable.
                </p>
                <br/><br/>

                <h4>Eligibility to Use</h4>
                <p>
                The Website can only be used by persons who can enter into legally binding contracts under the Indian Contract Act, 1872. So if you are a minor, i.e. Under 18, you can only use it with the help of a parent or guardian. Caretaker The Company does not accept responsibility for incorrect information given about your age.
                </p>
                <br/><br/>

                <h4>User Account, Password, and Security</h4>
                <p> 1. In order to use the Services through the Website, we may collect certain information from you, including without limitation like Name, username, email address, address, age, aadhar details, etc. </p>
                <p>2. You must ensure that the information you provide is true, complete, accurate and up to date.  </p>
                <p>3. The use of another user's data to use the services provided by the Company is expressly prohibited.   </p>
                <p>4. If you provide information that is false, inaccurate, incomplete or incomplete (or becomes incorrect, inaccurate, incomplete or incomplete) or the Company has reason to suspect that such information is false, inaccurate, incomplete or incomplete, the right to suspend your account or close.  </p>
                <p>5. We reserve the right to terminate your registration and/or deny you access to the Website if it becomes known to the Company or if it is discovered that you are under 18 years of age. </p>
                <p>6. We reserve the right to deny new users access to the services offered on the Website or to terminate access granted to existing users at any time without reason and you shall have no right to object.  </p>
                <p>7. You are responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under your Account.  </p> 
                <p>8. If you wish to delete or delete your information, including personally identifiable information, from the platform database, the Company reserves the right to process the deletion or removal without notifying  RMP or any third party of receipt of your request  to exercise your rights. delete the relevant data from the platform database and only after you have registered your express consent. Once your request has been processed, such deletion will be final and the Company will not be responsible for future transmission of such deleted information to you. The Company is also not responsible for RMP's failure to maintain separate records of such information in accordance with applicable laws.   </p>
                <p>9. You agree to (a) notify the Company immediately in writing of any compromise of your account or account information or any other breach of security or non-compliance with TP guidelines for which email notification should be sufficient, and (b) ensure that you opt out of your account at the end of each session/logout.  </p>
                <p>10. The Company cannot and will not be liable for any loss or damage from your failure to comply with this clause. You may be liable for damages incurred by the Company or any other user or visitor to the Website as a result of authorized or unauthorized use of your Account, if you have failed to keep your Account information secure and confidential or otherwise.   </p>
                <p>11. The website uses temporary cookies to store certain information (which is not sensitive personal data or information) that we use for the technical administration, research and development of the website and to manage users. In connection with the display of  advertisements or the optimization of the Services, we may allow authorized third parties to place or recognize a unique cookie on your browser. We do not store personally identifiable information in cookies. </p>
                <br/><br/>

                <h4>Telemedicine Services</h4>
                <p>Teekakaran provides offers which connects druggies directly to interpreters in real time, via live videotape conferencing, telephone and/ or secure messaging for the opinion and treatment of cases over the Internet, as well as furnishing other types of executive services and information( “ Telemedicine Services ”). All of the sharing interpreters are independent contractors and Teekakaran itself provides no telemedicine services. The interpreters are solely responsible for the Telemedicine Services.</p>
                <br/><br/>

                <h4>Definitions</h4>
                <p>
                1. Telemedicine means the delivery of health care services, where distance is an important factor, by all health professionals using information and communication technologies to communicate exchange valuable information for the diagnosis, treatment and prevention of disease and injury, in the interest of promoting health. The health of individuals and their communities. The main modes of telemedicine will include, among others, video; Audio or text (chat, picture, messaging, email, fax, etc.).  
                </p>
                <p>
                2. User/You means the patient, their representative or affiliate or any other person using your registered account to search for Physicians through the Website.  
                </p>
                <p>
                3. Practitioner means a Registered Medical Practitioner (RMP) under the provisions of the National Health Commission Act 2019 or a health care provider (whether an individual professional or institutional) or 'a similar organization wishing to be registered or  registered, on the Site, including designated and authorized associates of such practitioners or organizations. 
                </p>
                <p>
                4. Carer means a family member or other person authorized by the patient to represent the patient. If the patient's age is 16 years or younger, or if the patient is incapacitated (due to a mental disorder such as dementia or accidental physical incapacity), the caregiver is considered authorized to counsel instead. 
                </p>
                <p>
                For patients you agree and acknowledge that you must comply with all telemedicine management rules and regulations, including but not limited to telemedicine practice guidelines issued by the Department of Health and Welfare. Issued by the family ("City Guide"). 
                </p>
                <p>
                You also agree to notify yourself of any changes and/or releases of any laws, rules, regulations, notices, guidelines, etc. remote medical management. Under no circumstances will you comply with the principles to be followed when performing Telemedicine services. 
                </p>
                <p>
                By accessing or using the Website or registering for or using any of our services, you agree that you have read, understood and are bound by the following, including but not limited to:
                </p>

                <h5>1. Fees</h5>
                <p>
                Teekakaran has no part to play in determining the discussion freights of the guru. The discussion freights are determined at the discretion of the guru and Teekakaran may charge a subscription figure and levies whenever needed as per the programs of the Company. Stoner also understands that the figure charged by the guru is for a single person only. In case the stoner attempts to gain the discussion for further than one person, the guru shall not address the discussion demand for similar fresh person?   
                </p>

                <h5>2.	Not For exigency Use  </h5>
                <p>
                You admit that the Telemedicine Services are for general use only and not suitable for emergent or critical situations. IF YOU ARE FACING A MEDICAL EMERGENCY (moreover on your or on another person’s behalf), please communicate AN AMBULANCE SERVICE OR HOSPITAL DIRECTLY. You're advised to have a physical discussion in- person discussion.   
                </p>

                <h5>3.	Date storehouse  </h5>
                <p>
                You agree and admit that the Company may keep record of any relations and associated issues with the guru including but not limited to the stoner’s health issues and/ or the stoner’s gests. The Company may keep the forenamed data for the purpose of development of its services.   
                </p>

                <h5>4. Concurrence demand  </h5>
                <p>
                You admit that if the case himself herself initiates the Telemedicine Services, also the concurrence for seeking similar services sampled. However, health worker or guru initiates the Telemedicine Services on behalf of the case, also unequivocal concurrence (in the form of written concurrence, if a care giver.   
                </p>

                <h5>5.	Caregiver Terms   </h5>
                <p>
                In the event wherein Case is present with the Caregiver during the discussion.   Case isn't present with the Caregiver. This may be the case in the following - Case is a minor (aged 16 or lower) or the case is incapacitated, for illustration, in medical conditions like madness or physical disabilities. - The care giver is supposed to be authorized to consult on behalf of the case. - Caregiver has a formal authorization or a vindicated document establishing his relationship with the case and/ or has been vindicated by the case in a former in- person consult (unequivocal consult).   Still, we rigorously advise the Case and the Caregiver to be present during the discussion   
                </p>

                <h5>6.	Possible pitfalls   </h5>
                <p>
                The stoner understands that as with any medical procedure, there are pitfalls associated with the use of Telemedicine Services as the interpreters won't be conducting physical examination. You  easily understand and agree that You're  apprehensive of these limitations and  pitfalls which may include, without limitation, the following   Detainments in  discussion and evaluation or treatment may  do due to  scarcities or failures of specialized  outfit which may include poor audio/  videotape quality.  Failure of security protocols leading to a breach of sequestration of particular information.  Lack of access to complete medical history of the stoner may affect in adverse medicine relations or antipathetic responses or other negative issues.   
                </p>

                <h5>6.	Your guaranties and Representations   </h5>
                <p>
                These guaranties and representations shall also be applicable mutates mutandis to a caregiver seeking services over the platform. The stoner acknowledges, understands and agrees to the following   
                </p>
                <ol type="a">
                <li> You give express concurrence to use your information for cataloguing your appointment with the most suitable & available interpreters grounded on a software and you also understand that your information shall be used for billing purposes.  </li>
                <li>By using this platform, it’s supposed that you have acceded to entering telephonic calls, SMSs and/ or emails from us or any of our third- parties and the RMP. Similar dispatches shall be transferred to you on the telephone number and/ or dispatch id handed by you for the use of this operation which are subject to our sequestration Policy. Similar communication by us is for purposes that inter alia includes explanation calls, marketing calls and promotional calls.   </li>
                <li>The tradition handed by the guru to you is grounded on the Telemedicine commerce and may vary when examined in person. Further, the tradition handed is as per of the TP Guidelines and don't contain drugs enlisted in the “Banned List” as developed upon in the TP Guidelines. Hence similar tradition isn't be taken as a final and conclusive result.  </li>
                <li>Teekakaran isn't liable for any information  handed by the  stoner that's untrue, inaccurate, not current or deficient( or becomes untrue, inaccurate, not current or deficient), or Teekakaran has reasonable grounds to suspect that  similar information is untrue, inaccurate, not current or deficient, and Teekakaran has the right to suspend the services to the  stoner at its sole discretion.</li>
                <li>It's solely your responsibility to keep your correct mobile number and dispatch ID streamlined at all times. Teekakaran isn't responsible for any loss or vexation caused due to your failure to update your contact details on the website app.   </li>
                <li>Notwithstanding anything contained herein, Teekakaran isn't in any manner responsible for any medicine/ drugs specified or the remedy specified by the guru.   </li>
                <li>Druggies shall not convert any guru to define medicines similar as - drug for Medical Termination of Pregnancy Habit forming medicines i.e.  medicines in Schedule X of medicines and Cosmetics Rules, 1945  -Narcotic or psychotropic  medicines regulated by Narcotic medicines and Psychotropic Substances Act, 1985  - medicines contained in the Banned List of TG Guidelines.   </li>
                <li>In case the stoner wants to point out any disagreement with the tradition or wishes to add, cancel or modify anything in the said tradition, stoner should communicate the guru directly. Teekakaran makes no representation for the information participated by the guru.   </li>
                <li>The guru may exercise his/ her discretion in concluding for a particular mode of tele- discussion depending on the nature of the disease addressed by the Case, and as per applicable laws. Teekakaran has no part in determining the mode of tele- discussion with the guru.   </li>
                <li>
                The stoner acknowledges that Company is a bare facilitator and doesn't fall within the horizon of the National Medical Commission Act, 2019, and the rules and regulations framed honoured thereunder.   
                </li>
                </ol>
                

                <h5>7. Grievance Redressal   </h5>
                <p>
                Our Grievance redressal policy can be penetrated then. </p>
                <ol type="a">
                <li>a. Platform, this Agreement, if you have any questions concerning this Website</li>
                <li>b. In agreement with the Information Technology Act, 2000, and the rules made there under, if you have any grievance with respect to the Website or the service, including any disagreement and grievances with respect to processing of information, you can communicate our Grievance department info@Teekakaran.com. In the event you suffer as a result of access or  operation of our Website by any person in violation of Rule 3 of the Information Technology( interposers Guidelines) Rules, 2011, please address your grievance to the below person.   
                </li>
                </ol>
                <h5>8. Teekakaran Guaranties   </h5>
                <ol type="a">
                    <li>The tradition given by the guru along with the tab for the service shall be uploaded on the stoner’s account after the discussion.   </li>
                    <li>The Website may be linked to the website of third parties, cells and business mates. Teekakaran has no control over, and not liable or responsible for content, delicacy, validity, trust ability, quality of similar websites or made available by/ through our Website. Addition of any link on the Website doesn’t indicate that Teekakaran endorses the linked point. Druggies may use the links and these services at their own threat.   </li>
                    <li>The Platform is handed on a best- sweats “AS IS” base. While we strive to maintain the loftiest situations of service vacuity, Teekakaran isn't liable for any interruption that may be caused to your access of the Services.   </li>
                    <li>It's hereby expressly clarified that, the Information that you gain or admit from Teekakaran, and its workers, contractors, mates, guarantors, advertisers, licensors or else on the Website is for instructional purposes only. We make no guarantees, representations or guaranties, whether expressed or inferred, with respect to professional qualifications, quality of work, or other information handed on the Website. Under no event, shall we be liable to you or anyone differently for any decision made or action taken by you in reliance on similar information. Any information about the interpreters, including but not limited to their name, photos, qualifications, experience and specialties, that may be  handed on the Website, is for information purposes only and  similar information shall not be  supposed to  quantum to an  announcement of the guru, and/ or the services  handed by  similar guru.</li>
                </ol>
                <br/><br/>


                <h4>Intellectual Property Rights  </h4>
                <p>
                1. The Website and the processes, and their selection and arrangement, including but not limited to all  textbook,  plates,  stoner interfaces, visual interfaces, sounds and music( if any), artwork and computer  law(  inclusively, the" proprietor Content") on the Website is  possessed and controlled by Company and the design, structure, selection, collaboration, expression, look and feel and arrangement of  similar proprietor Content is  defended by brand, patent and trademark laws, and  colourful other intellectual property rights.   
                </p>
                <p>
                2. The trademarks, ensigns and service marks displayed on the Website (" Marks") are the property of Company or their third parties or separate third parties. You aren't permitted to use the Marks without the previous concurrence of Company, the applicable third parties that may enjoy the Marks.   
                </p>
                <p>
                3. Unless  else indicated or anything contained to the contrary or any personal material  possessed by a third party and so expressly mentioned, Company or its third- parties owns all intellectual property rights to and into the trademark of the Company and the Website, including, without limitation, any and all rights, title and interest in and to brand, related rights, patents,  mileage models, designs, know- how, trade secrets and inventions( patent pending), goodwill, source  law, meta  markers, databases,  textbook, content,  plates, icons, and hyperlinks.   
                </p>
                <p>
                4. Except as expressly  handed herein, You admit and agree that You shall not copy, copublish, post, display,  restate, transmit, reproduce or distribute any Content through any medium without  carrying the express written  authorization from company or third party  proprietor of  similar Content.  
                </p>
                <br/><br/>

                <h4>Assumption of Risk  </h4>
                <p>The website and its contents are for communication purposes only. By using the website you hereby admit and agree that any information posted on our website isn't intended to be any kind of legal advice, medical advice or fiscal advice and no fiduciary relationship has been created between you and the company. You further agree that your purchase of any of the products on the website is at your own threat. We don't assume any responsibility or liability for any advice or any other information given on the website.   </p>
                <br/><br/>

                <h4>Force Majeure  </h4>
                <p>
                1. You accept and admit that we shall not be liable for any loss or damage caused to You as a result of  detention or  dereliction or  insufficiency or failure in the Services as a result of any natural disasters, epidemic, fire,  screams, civil disturbances,  conduct or  rulings of governmental bodies, communication line failures( which aren't caused due to the fault of Teekakaran or the Third Party Service Providers), or any other  detention or  dereliction or  insufficiency or failure which arises from causes beyond our reasonable control( “ Force Majeure Event ”).   
                </p>
                <p>
                2. In the event of any Force Majeure Event arising, depending on whose performance has been impacted under the Terms of Use, the party shall incontinently give notice to the Other Party(s) of the data which constitute the Force Majeure Event.   
                </p>
                <p>
                3. You explicitly give your concurrence to the Company to use your information in the event of any epidemic extremities to feed towards the benefit of public at large. The Company may partake your information with nonsupervisory bodies to fight any epidemic exigency.  
                </p>
                <br/><br/>

                <h4>Indemnification</h4>
                <p>You agree to defend and compensate the company and any of its cells, workers, agents, directors and representatives and hold us inoffensive against any and all legal claims and demands, including but not limited to attorney’s freights, which may arise from your or relate to your use or abuse of the of the website or the services, your breach of these terms or your conduct or conduct. You agree that the company shall, if it so desires, elect its own legal counsel and share in its own defence.  </p>
                <br/><br/>

                <h4>Termination  </h4>
                <p>
                1. The Terms will continue to apply until terminated by either you or Company as set forth below. However, You may do so by  If You want to terminate Your agreement with Company.( i) not  penetrating the Website; or  ii) closing Your accounts for all of the services that You use, where Company has made this option available to You.   
                </p>
                <p>
                2. Company may, at any time, with or without notice, terminate the Terms( or portion thereof,  similar as any individual fresh Terms) with You if  - You  transgress any of the Terms, the sequestration Policy or any other terms, conditions, or  programs that may be applicable to You from time to time( or have acted in a manner that  easily shows that You don't intend to, or are  unfit to, misbehave with the same);  
                </p>
                <p>
                Company is  needed to do so by law( for  illustration, where the provision of the services hereunder, to You is, or becomes, unlawful);  - The provision of the services to You, by Company is, in Company's opinion, no longer commercially  feasible;  - Company has  tagged to discontinue, with or without reason, access to the Website, the services( or any part thereof); or   Company may also terminate or suspend all or a portion of Your account or access to the services with or without reason. 
                </p>
                <p>
                Except as may be set forth in any fresh Terms applicable to a particular service, termination of Your Account may include( i)  junking of access to all immolations within the Website or with respect to the services;( ii)  omission of Your accoutrements  and Account Information, including Your  particular information, log- in ID and  word, and all affiliated information,  lines and accoutrements  associated with or inside Your Account( or any part thereof); and( iii) barring of  farther use of the Services.   You agree that all terminations shall be made in Company's sole discretion and that Company shall not be liable to You or any third party for any termination of Your Account( and accompanying  omission of Your Account Information), or Your access to the Website and the services offered thereunder.   
                </p>
                <p>
                Notwithstanding the foregoing, these Terms will survive indefinitely unless and until Company chooses to terminate them. Still, Company may cancel any content or other accoutrements relating to your use of the Website and Company will have no liability to you or any third party for doing so, If You or Company terminates your use of the Website.  
                </p>
                <br/><br/>

                <h4>Governing Law and disagreement Resolution  </h4>
                <p>
                These Terms of Use and any contractual obligation between the Parties will be governed by the laws of India, with the exception of its conflict of law. In case any action specifically permitted under these terms is initiated, the parties shall submit to the exclusive governance of the courts at Mumbai, Maharashtra, India.  
                </p>
                <br/><br/>

                <h5>Revision and Variation  </h5>
                <p>The company may, at any time, change these terms without giving any notice to you. You hereby understand and agree that we've the right to modify these terms and any information and links contained herein. You further agree that any and all  variations to these terms shall be in full force and effect  incontinently upon being posted on the website and shall replace any  previous  interpretation of these terms.  </p>
                <p>Report Abuse In the event you come across any abuse or violation of these Terms or if you come apprehensive of any reprehensible content on the Website, please report to Company’s client support platoon.  
                </p>
                <br/><br/>

                <h5>Privacy Policy </h5>
                <p>
                Company views protection of your sequestration as a veritably important principle. We store and process Your Account Information including any sensitive  particular/  fiscal information collected( as defined under the Information Technology Act, 2000), if any, on computers that may be  defended by physical as well as reasonable technological security measures and procedures in  agreement with Information Technology Act 2000 and Rules there under. </p>
                <p>
                Company’s current sequestration Policy is available at https//Teekakaran.com/privacy. However, please don't use Website, If You expostulate to Your Information being transferred or used in this way?   Company will partake/ sell/ transfer/ license/ gaggle some or all of your particular information with another business reality should we (or our means) plan to combine with or are acquired by that business reality, organization, admixture, restructuring of business or for any other reason whatsoever. Should such a sale or situation do, the other business reality or the new concerted reality will be needed to follow the sequestration policy with respect to your particular information.</p>
                <p>
                Once You  give Your information to us, You  give  similar information to Company and  cells of Company and Company and its  chapter may use  similar information to  give You  colourful services with respect to Your  sale whether  similar  sale are conducted on Teekakaran or with third party  trafficker's or third party  trafficker's website. You accept the terms hereof and hereby concurrence to us, participating and/ or processing your information with third parties in an anonymized and aggregate manner.   </p>
                <p>
                The stoner hereby warrants, expresses and agrees that he she has read and completely understands the sequestration Policy of Company in respect of the Website. You further assent that the terms and contents of similar sequestration Policy are respectable to you.  </p>
                <br/><br/>

                <h5>Survival  </h5>
                <p>Indeed after termination, certain scores mentioned under Covenants, Liability, Indemnity, Intellectual Property, disagreement Resolution will continue and survive termination.   </p>
                <br/><br/>

                <h5>Severability  </h5>
                <p>Still, unlawful, void or for any other reason unenforceable, if any provision of these Terms of Use is supposed invalid.  </p>
                <br/><br/>

                <h5>Waiver  </h5>
                <p>In the event that we fail to apply any vittles’ of these terms, this shall not constitute a disclaimer of any enforcement of that provision or any other provision. No disclaimer of these terms will be valid unless the same is in jotting and inked by us.  </p>
                <br/><br/>

                <h5>Headlines  </h5>
                <p>The headlines and heads herein are included for convenience and association only and aren't intended to describe, interpret, define or limit the compass, extent or intent of these Terms.</p>
                <br/><br/> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermCondition;
