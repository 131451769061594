import React from 'react'

const Feature = () => {
  return (
    <>
    <div className='sendsms' id="sendSMS">
    <h2> Send App Link</h2>
      <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
        <div className="form-group">
          <input type="email" className="form-control" placeholder="Enter your email" />
        </div>
        <div className="form-group">
          <button className="btn">SEND lINK</button>
        </div>
      </form>
    </div>
    <section className="row_am features_section" id="features">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">

          <h2><span>Features</span>  that make one-of a-kind</h2>
          <p>Facilitating access to a wide range of services and revolutionizing the healthcare industry.</p>
        </div>
        <div className="feature_detail">

          <div className="left_data feature_box">


            <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/vacc.png" alt="image" />
                <h4>TeekaKaran</h4>
              </div>
              <div className="text">
                <p>Streamline the vaccination process and schedule in the most convenient and accessible way</p>
              </div>
            </div>


            <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/cri_icon.png" alt="image" />
                <h4>CRAI</h4>
              </div>
              <div className="text">
                <p>It’s a AI generated tool which is helpful in understanding the behaviour of child</p>
              </div>
            </div>

            <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/erecord-icon.png" alt="image" />
                <h4>E-Records</h4>
              </div>
              <div className="text">
                <p>Manage your prescriptions, reports, and all your medical history electronically</p>
              </div>
            </div>
          </div>


          <div className="right_data feature_box">


            <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/doc_app.png" alt="image" />
                <h4>E-Appointment</h4>
              </div>
              <div className="text">
                <p>It allows patients to schedule and manage appointments with healthcare providers effortlessly</p>
              </div>
            </div>

            <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/pharmacy.png" alt="image" />
                <h4>E-Pharmacy</h4>
              </div>
              <div className="text">
                <p>Effortless online pharmacy delivery, bringing prescribed medications and health products right to your doorstep, saving you time and ensuring your well-being</p>
              </div>
            </div>

            <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
              <div className="icon">
                <img src="/images/lab_test.png" alt="image" />
                <h4>E-Labs</h4>
              </div>
              <div className="text">
                <p>We integrate several path labs for doorstep services and expedite accurate and timely diagnoses</p>
              </div>
            </div>

          </div>

          <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
            <img src="/images/features_frame-1.png" alt="image" />
          </div>
        </div>
      </div>

    </section>
    </>
  )
}

export default Feature;