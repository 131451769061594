import React, { useEffect } from "react";
import Header from "../../components/landingpage/Header";
import Hero from "../../components/landingpage/Hero";
import "../../styles/landingPage/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Trusted from "../../components/landingpage/Trusted";
import Feature from "../../components/landingpage/Feature";
import Views from "../../components/landingpage/Views";
import Modern from "../../components/landingpage/Modern";
import Works from "../../components/landingpage/Works";
import Customer from "../../components/landingpage/Customer";
import Team from "../../components/landingpage/Team";
import Faq from "../../components/landingpage/Faq";
import Interface from "../../components/landingpage/Interface";
import AppSection from "../../components/landingpage/AppSection";
import Story from "../../components/landingpage/Story";
import NewsLetter from "../../components/landingpage/NewsLetter";
import Footer from "../../components/landingpage/Footer";
import Dashboard from "../../components/landingpage/dashboard";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="page_wrapper">
      <Header />
      <Hero />
      <Feature />
      <Views />
      
      
      {/* <Customer /> */}
      <Modern/>
      <Dashboard/>
      <Works />
      <Team />
      <Trusted/>
      <Faq />
      {/* <Interface/> */}
      <AppSection />
      {/* <Story /> */}
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
