import React from "react";

const Works = () => {
  return (
    <section className="row_am how_it_works" id="how_it_work">
      <div className="container">
        <div className="how_it_inner">
          <img
            src="/images/Recordified-round.png"
            className="top_circle_frame"
          />
          {/* <img
            src="/images/Recordified-round.png"
            className="bottom_circle_frame"
          /> */}
          <div className="row align-items-center">
            <div className="col-lg-6">
            <img src="/images/bhu-image.jpg" alt="image" />
            </div>
            <div className="col-lg-6">
              <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <h2>
                  <span>Awards and Recognition </span>{" "}
                </h2>
              </div>
              <p>We hold prestigious recognition and a tangible commitment to quality in healthcare services and represents a commitment to excellence, patient safety, and continuous improvement. </p>
              <div className="incubated_by pt-4">
                <h4>Incubated with </h4><img src="/images/bhu-logo.png" alt="bhu-logo" />
              </div>
            </div>
            {/* <div className="col-lg-7">
              <div className="step_block">
                <div className="row align-items-center how-it-works">
                  <div className="col-2 text-center bottom">
                    <div className="step_number">
                      <h3>01</h3>
                    </div>
                  </div>
                  <div className="col-8 flex-sec_left">
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img src="/images/work-1.png" alt="image" />
                    </div>
                    <div
                      className="step_text"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h4>Download App</h4>
                    </div>
                  </div>
                </div>
                <div className="row timeline">
                  <div className="col-2">
                    <div className="corner top-right"></div>
                  </div>
                  <div className="col-8">
                    <hr />
                  </div>
                  <div className="col-2">
                    <div className="corner left-bottom"></div>
                  </div>
                </div>

                <div className="row align-items-center justify-content-end how-it-works">
                  <div className="col-8 text-right flex-sec_right">
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img src="/images/work-2.png" alt="image" />
                    </div>
                    <div
                      className="step_text"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h4>Registration Process</h4>
                    </div>
                  </div>
                  <div className="col-2 text-center full">
                    <div className="step_number">
                      <h3>02</h3>
                    </div>
                  </div>
                </div>
                <div className="row timeline">
                  <div className="col-2">
                    <div className="corner right-bottom"></div>
                  </div>
                  <div className="col-8">
                    <hr />
                  </div>
                  <div className="col-2">
                    <div className="corner top-left"></div>
                  </div>
                </div>

                <div className="row align-items-center how-it-works">
                  <div className="col-2 text-center full left">
                    <div className="step_number">
                      <h3>03</h3>
                    </div>
                  </div>
                  <div className="col-8 flex-sec_left">
                    <div
                      className="step_img"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img src="/images/work-3.png" alt="image" />
                    </div>
                    <div
                      className="step_text"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <h4>Get the QR</h4>
                    </div>
                  </div>
                </div>
                <div className="row timeline">
                  <div className="col-2">
                    <div className="corner top-right"></div>
                  </div>
                  <div className="col-8">
                    <hr />
                  </div>
                  <div className="col-2">
                    <div className="corner left-bottom"></div>
                  </div>
                </div>

                <div className="row align-items-center justify-content-end how-it-works">
                  <div className="col-8 text-right flex-sec_right">
                    <div
                      className="step_img"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img src="/images/work-4.png" alt="image" />
                    </div>
                    <div
                      className="step_text"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <h4>Woala! We are done.</h4>
                    </div>
                  </div>
                  <div className="col-2 text-center top">
                    <div className="step_number">
                      <h3>04</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className="yt_video" data-aos="fade-in" data-aos-duration="1500">

          <div className="anim_line dark_bg">
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
            <span><img src="/images/anim_line.png" alt="anim_line" /></span>
          </div>
          <div className="thumbnil">
            <img src="/images/yt_thumb.png" alt="image" />
            <a className="popup-youtube play-button" data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU">
              <span className="play_btn">
                <img src="/images/play_icon.png" alt="image" />
                <div className="waves-block">
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </div>
              </span>
              Let’s see virtually how it works
              <span>Watch video</span>
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Works;
