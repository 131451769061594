import React, { useEffect, useState } from "react";

const AppSection = () => {
  useEffect(() => {
    const position = document
      .querySelector(".free_text")
      .getBoundingClientRect();
  }, []);

  const isSticky = (e) => {
    const position = document.querySelector(".free_text");
    if (position.top < window.innerHeight && position.bottom >= 0) {
      document.querySelector(".purple_backdrop").add("is-sticky");
    } else {
      document.querySelector(".purple_backdrop").remove("is-sticky");
    }
  };
  return (
    <section className="row_am free_app_section" id="getstarted">
      <div className="purple_backdrop"></div>
      <div className="container">
        <div
          className="free_app_inner"
          data-aos="fade-in"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <div className="anim_line dark_bg">
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="free_text">
                <div className="section_title">
                  <h2>Your personal assistant awaits: Get the app</h2>
                  <p>
                    Leveraged by the capabilities of modern technologies,
                    Teekakaran enhances and rationalizes healthcare sector.{" "}
                  </p>
                </div>
                <ul className="app_btn">
                  <li>
                    <a href="#">
                      <img src="/images/appstore_blue.png" alt="image" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dev.teekakaran"
                      target="_blanck"
                    >
                      <img src="/images/googleplay_blue.png" alt="image" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="free_img">
                <img src="/images/download-screen01.png" alt="image" />
                <img
                  className="mobile_mockup"
                  src="/images/download-screen02.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppSection;
