import React, { useEffect } from "react";
import Header from "../../components/landingpage/Header";
import Footer from "../../components/landingpage/Footer";
import "../../styles/landingPage/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getBlogByIdAction } from "../../redux/slice/blogs/getBlogByIdSlice";
import SEO from "../common/SEO";

const SingleBlog = () => {
  const { slug } = useParams();
  console.log(slug, "sjytuyst");
  const { getBlogById } = useSelector((state) => state);

  console.log(getBlogById);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getBlogByIdAction(slug));
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    if (getBlogById.success) {
      window.title = getBlogById?.blog.name || "Teekakaran";
    }
  }, [getBlogById.success]);

  return (
    <>
      <Header />
      {/*  BredCrumb-Section  */}
      <SEO
        title={getBlogById.blog.metaTitle}
        description={getBlogById.blog.metaDescription}
        keywords={getBlogById.blog.metaKeyword}
      />
      <div
        className="bred_crumb"
        style={{
          backgroundImage: 'url("/images/bread_crumb_bg.png")',
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          {/* shape animation   */}
          <span className="banner_shape1">
            {" "}
            <img src="/images/banner-shape1.png" alt="image" />{" "}
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="/images/banner-shape2.png" alt="image" />{" "}
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="/images/banner-shape3.png" alt="image" />{" "}
          </span>

          <div className="bred_text">
            <h1>Latest Blog Post</h1>
            <ul>
              <li>
                <a href="./">Blog</a>
              </li>
              <li>
                <span>»</span>
              </li>
              <li>
                <a href="#">Single Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="blog_detail_section">
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="review">
              <span>Review</span>
              <span>45 min ago</span>
            </div>
            <div className="section_title">
              <h2>{getBlogById?.blog.name}</h2>
            </div>
            <div className="main_img">
              <img src={getBlogById?.blog.imageBlog} alt="image" />
            </div>
            {/* <div className="info">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
              <p>Printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unnown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys centuries, but also the leap into electronic.</p>
              <h3>Why we are best</h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
              <ul>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing and typesetting in </p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since the when</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only five centuries, but also the leap into electronic.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only.</p></li>
              </ul>
            </div> */}
            <div className="info">
              <p>{getBlogById?.blog.metaDescription}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: getBlogById?.blog.description,
                }}
              />
            </div>
            {/* <div className="two_img">
              <div className="row">
                <div className="col-md-6">
                  <img src="/images/blog_sub_01.png" alt="image"/>
                </div>
                <div className="col-md-6">
                  <img src="/images/blog_sub_02.png" alt="image"/>
                </div>
              </div>
            </div>
            <div className="info">
              <h3>Why we are best</h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
            </div>
            <div className="quote_block">
              <span className="q_icon"><img src="/images/quote_icon.png" alt="image"/></span>
              <h2>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy.</h2>
              <p><span className="name">Mr. John Doe,</span> Apper Inc</p>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic industry.</p>
            <div className="blog_authore">
                <div className="authore_info">
                    <div className="avtar">
                      <img src="/images/blog_d02.png" alt="image"/>
                    </div>
                    <div className="text">
                      <h3>By: John Dow</h3>
                      <span>July 25, 2022</span>
                    </div>
                </div>
                <div className="social_media">
                  <ul>
                  <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                  </ul>
                </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="blog_detail_section">
      <div className="container">
        <div className="blog_inner_pannel">
            <div className="review">
              <span>Review</span>
              <span>45 min ago</span>
            </div>
            <div className="section_title">
              <h2>Top rated app of the year!</h2>
            </div>
            <div className="main_img">
              <img src="/images/blog_detail_main.jpg" alt="image"/>
            </div>
            <div className="info">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
              <p>Printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unnown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys centuries, but also the leap into electronic.</p>
              <h3>Why we are best</h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
              <ul>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing and typesetting in </p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since the when</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only five centuries, but also the leap into electronic.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only.</p></li>
              </ul>
            </div>
            <div className="two_img">
              <div className="row">
                <div className="col-md-6">
                  <img src="/images/blog_sub_01.png" alt="image"/>
                </div>
                <div className="col-md-6">
                  <img src="/images/blog_sub_02.png" alt="image"/>
                </div>
              </div>
            </div>
            <div className="info">
              <h3>Why we are best</h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
            </div>
            <div className="quote_block">
              <span className="q_icon"><img src="/images/quote_icon.png" alt="image"/></span>
              <h2>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy.</h2>
              <p><span className="name">Mr. John Doe,</span> Apper Inc</p>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic industry.</p>
            <div className="blog_authore">
                <div className="authore_info">
                    <div className="avtar">
                      <img src="/images/blog_d02.png" alt="image"/>
                    </div>
                    <div className="text">
                      <h3>By: John Dow</h3>
                      <span>July 25, 2022</span>
                    </div>
                </div>
                <div className="social_media">
                  <ul>
                  <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                  </ul>
                </div>
            </div>
        </div>
      </div>
    </section> */}
      <Footer />
    </>
  );
};

export default SingleBlog;
