import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllCmsBySlugAction } from "../../redux/slice/cms/getAllCmsBySlugSlice";

const Footer = () => {
  const [cms, setCms] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  console.log(cms, "sdhk");

  const handleCMS = (event) => {
    event.preventDefault(); // Prevent the default navigation behavior
    setCms("privacy-policy");
    // Manually navigate to the "/term" route
  };
  const handleTerm = (event) => {
    event.preventDefault(); // Prevent the default navigation behavior
    setCms("term-and-condition");
    // Manually navigate to the "/term" route
  };
  const handlerefund = (event) => {
    event.preventDefault(); // Prevent the default navigation behavior
    setCms("cancellation-refund");
    // Manually navigate to the "/term" route
  };

  const { getAllCmsBySlug } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllCmsBySlugAction({ slug: cms }));
  }, [cms]);

  useEffect(() => {
    if (getAllCmsBySlug.success && cms == "privacy-policy") {
      navigate("/privacy");
    }
    if (getAllCmsBySlug.success && cms == "term-and-condition") {
      navigate("/term-condition");
    }
  }, [getAllCmsBySlug.success, navigate]);

  console.log(cms);

  // console.log(getAllCmsBySlug,"SlugSlice")
  return (
    <>
      <footer>
        <div className="top_footer" id="contact">
          <div className="anim_line dark_bg">
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="/images/anim_line.png" alt="anim_line" />
            </span>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <div className="logo">
                    {" "}
                    <img src="/images/logo-white.png" alt="image" />
                  </div>
                  <ul>
                    <li>
                      <a href="mailto:support@teekakaran.com">
                        support@teekakaran.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:9560417060">+91-9560417060</a>
                    </li>
                  </ul>
                  <ul className="social_media">
                    <li>
                      <a href="https://www.facebook.com/digi.teekakaran">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/digi.teekakaran/">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/teekakaran/?viewAsMember=true ">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@teekakaran ">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Useful Links</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#about_app">About us</a>
                    </li>
                    <li>
                      <a href="/blog/">Blog</a>
                    </li>
                    <li>
                      <a data-bs-toggle="modal" data-bs-target="#contact-model">
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Help & Suport</h3>
                  <ul>
                    <li>
                      <a href="/#faq_section">FAQs</a>
                    </li>
                    <li>
                      <a href="/#how_it_work">How it works</a>
                    </li>
                    <li>
                      <Link onClick={handleTerm} to="/term-condition">
                        Terms & conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleCMS}
                        to="/privacy"
                        className="text-decoration-none"
                      >
                        Privacy policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://teekakaran.com/cancellation-refund"
                        className="text-decoration-none"
                      >
                        Cancellation and Refund Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12">
                <div className="try_out">
                  <h3>Let’s Try Out</h3>
                  <ul className="app_btn">
                    <li>
                      <a href="#">
                        <img src="/images/appstore_blue.png" alt="image" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dev.teekakaran"
                        target="_blanck"
                      >
                        <img src="/images/googleplay_blue.png" alt="image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <p>© Copyrights 2023. All rights reserved.</p>
              </div>{" "}
              <div className="col-md-6">
                <p className="developer_text text-center">
                  BSI Business Park, H-44, Sector 63, Noida, Uttar Pradesh
                  201301
                </p>
              </div>
              <div className="col-md-3">
                <p className="developer_text">
                  Design & developed by Teekakaran
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="go_top">
          <span>
            <img src="/images/go_top.png" alt="image" />
          </span>
        </div>

        <div
          className="modal fade"
          id="contact-model"
          tabindex="-1"
          aria-labelledby="contact-model"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="model-close">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h3 className="modal-title" id="contact-modelLabel">
                  Contact Us
                </h3>
                <p>For more information. Please complete this form.</p>
                <form
                  action=""
                  data-aos="fade-in"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label for="your-name" className="form-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="your-name"
                        name="your-name"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label for="your-surname" className="form-label">
                        Your Surname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="your-surname"
                        name="your-surname"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label for="your-email" className="form-label">
                        Your Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="your-email"
                        name="your-email"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label for="your-subject" className="form-label">
                        Your Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="your-subject"
                        name="your-subject"
                      />
                    </div>
                    <div className="col-12">
                      <label for="your-message" className="form-label">
                        Your Message
                      </label>
                      <textarea
                        className="form-control"
                        id="your-message"
                        name="your-message"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <input
                            type="button"
                            value="Submit"
                            className="form_submit_btn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
