import React from "react";

const Dashboard = () => {
  return (
    <section class="row_am  dashboard">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">

            <div class="dash_img aos-init aos-animate" data-aos="fade-in" data-aos-duration="1500">
              <div class="frame_img">
                <img class="moving_position_animatin" src="images/dashboard.jpg" alt="image"/>
              </div>
            </div>
          </div>
          <div class="col-lg-5">


            <div class="dash_text">
              <div class="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">


                <h2>Control Panel</h2>

                <p>
                Our advanced backend tool serves as the central nerve system for healthcare operations of hospitals, doctors, labs, and pharmacy. At its core, sophisticated backend system acts as a unified control panel, allowing administrators to monitor, analyse, and optimize various aspects of healthcare delivery.
                </p>
              </div>


             
            </div>
          </div>
        </div>
        
      </div>

    </section>
  );
};

export default Dashboard;
