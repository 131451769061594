import React from "react";

const Team = () => {
  return (
    <>
      <div className="sendsms" id="sendSMS">
        <h2> Send App Link</h2>
        <form
          action=""
          data-aos="fade-in"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <button className="btn">SEND lINK</button>
          </div>
        </form>
      </div>
      <section className="row_am experts_team_section" id="team">
        <div className="container">
          <div
            className="team_inner-section"
            
          >
            <div
              className="section_title "
             
            >
              <h2>
                {" "}
                Meet our <span> experts </span>
              </h2>

            </div>
            <div className="row co_row">
              <div
                className="col-md-6 col-lg-6">
                <div className="experts_box">
                  <img src="images/mukul.png" alt="image" />
                  <div className="text">
                    <h3>Mukul Grover</h3>
                    <span>CO-Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="experts_box">
                  <img src="images/manish.png" alt="image" />
                  <div className="text">
                    <h3>Manish Agrawal </h3>
                    <span>CO-Founder</span>
                  </div>
                </div>
              </div>
              {/* <div
                className="col-md-6 col-lg-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <div className="experts_box">
                  <img src="images/team-2.png" alt="image" />
                  <div className="text">
                    <h3>Dr.Prakhar Garg </h3>
                    <span>Sr.Advisor</span>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="400"
              >
                <div className="experts_box">
                  <img src="images/team-3.png" alt="image" />
                  <div className="text">
                    <h3>Dr.Kapil Agrawal</h3>
                    <span>Advisor</span>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row team_row">
              <div className="col-md-6 col-lg-3" >
                <div className="experts_box">
                  <img src="images/megha.png" alt="image" />
                  <div className="text">
                    <h3>Megha Sharma</h3>
                    <span>Communication</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 ">
                <div className="experts_box">
                  <img src="images/vaibhav.png" alt="image" />
                  <div className="text">
                    <h3>Vaibhav Bhatnagar</h3>
                    <span>Research & Marketing</span>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 aos-init aos-animate">
                <div className="experts_box">
                  <img src="images/jayshree.png" alt="image" />
                  <div className="text">
                    <h3>Jayshree Bhunia </h3>
                    <span>Product Design</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 ">
                <div className="experts_box">
                  <img src="images/ranjeet.png" alt="image" />
                  <div className="text">
                    <h3>Ranjeet Kumar </h3>
                    <span>Development</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sendsms" id="sendSMS">
        <h2> Send App Link</h2>
        <form
          action=""
          data-aos="fade-in"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <button className="btn">SEND lINK</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Team;
