import { toast } from "react-toastify";

// Function to call api
// api/apiUtils.js
export async function makeApiRequest(
  url,
  { method = "GET", data = null, token = null, apiKey = null }
) {
  try {
    let newOptions = {
      method,
      body: data,
    };

    const headers = {};

    if (token) {
      headers["x-access-token"] = token;
    }

    if (apiKey) {
      headers["x-api-key"] = apiKey;
    }

    if (data === null) {
      newOptions = newOptions;
    } else if (data instanceof FormData) {
      newOptions = { ...newOptions, body: data };
    } else {
      headers["Content-Type"] = "application/json";
      newOptions = { ...newOptions, body: JSON.stringify(data) };
    }

    newOptions.method = method;
    newOptions.headers = headers;

    const response = await fetch(
      process.env.REACT_APP_BASE_URL + url,
      newOptions
    );
    const processedData = await response.json();

    if (response.ok) {
      return processedData;
    } else {
      throw new Error(processedData.message);
    }
  } catch (error) {
    throw error;
  }
}

export function handleRelationLanguage(relation) {
  switch (relation) {
    case "पिता":
      return "Father";
    case "माता":
      return "Mother";
    case "अन्य":
      return "Other";
    default:
      return relation;
  }
}

// Notification message
export const notify = (message, type = "success") => {
  const option = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "success") {
    toast.success(message, option);
  } else if (type === "warn") {
    toast.warn(message, option);
  } else if (type === "error") {
    toast.error(message, option);
  }
};
