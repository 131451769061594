import { configureStore } from "@reduxjs/toolkit";
import getAllCmsBySlugSlice from "./slice/cms/getAllCmsBySlugSlice";
import getAllBlogSlice from "./slice/blogs/getAllBlogSlice";
import getBlogByIdSlice from "./slice/blogs/getBlogByIdSlice";
import showAppointmentSlice from "./slice/appointment/showAppointmentSlice";

export const store = configureStore({
  reducer: {
    // CMS
    getAllCmsBySlug: getAllCmsBySlugSlice,

    // Blog
    getAllBlog: getAllBlogSlice,
    getBlogById: getBlogByIdSlice,

    showAppointment: showAppointmentSlice,
  },
});
